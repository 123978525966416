/* eslint-disable array-callback-return */
import {
  FormControlLabel,
  Checkbox,
  InputLabel,
  TableBody,
  TableCell,
  TableRow,
  Table,
} from "@mui/material";
import "./styles.css";
import { FC } from "react";
import { CheckBoxOutlined } from "@mui/icons-material";
import { Features } from "../../../../../shared/enum";

type PermissionsProps = {
  rolePermissions: string[];
  rolesForm: any;
};

class Entity {
  permissions: any;
  feature: any;
  featureObject: any = Features;
  crudArray = ["Read", "Create", "Update", "Delete"];
  constructor(name: string) {
    this.feature = name;
    if (this.featureObject?.[name]?.specialPermissions) {
      this.crudArray = this.featureObject?.[name]?.specialPermissions;
    }
    this.permissions = this.crudArray?.map((data) => {
      return {
        value: `${name}-${data}`,
        name: data,
      };
    });
  }
  getAssociatedReadPermissions(action: string) {
    let associatedEntities;
    if (action) {
      associatedEntities =
        this.featureObject?.[this.feature]?.[
          ["Create", "Update"]?.includes(action) ? "Write" : action
        ];
    } else {
      associatedEntities = [
        ...new Set([
          ...(this.featureObject?.[this.feature]?.Write ?? []),
          ...(this.featureObject?.[this.feature]?.Read ?? []),
        ]),
      ];
    }
    this.permissions =
      associatedEntities?.map((data: any) => {
        return `${data}-${this.crudArray[0]}`;
      }) ?? [];
    return this.permissions;
  }
}
const features = Object.keys(Features);

const Permissions: FC<PermissionsProps> = ({ rolesForm }) => {
  const handleSelectAll = (entity: any, checked: boolean) => {
    const permissions = entity.permissions.map(
      (permission: any) => permission.value
    );
    if (checked) {
      rolesForm.setValues((prevData: any) => {
        return {
          ...prevData,
          rolePermissions: [
            ...new Set([
              ...prevData.rolePermissions,
              ...permissions,
              ...entity.getAssociatedReadPermissions(""),
            ]),
          ],
        };
      });
    } else {
      rolesForm.setValues((prevData: any) => {
        return {
          ...prevData,
          rolePermissions: prevData?.rolePermissions?.filter(
            (rolePermission: string) => {
              return !permissions.includes(rolePermission);
            }
          ),
        };
      });
    }
    return permissions;
  };

  const isChecked = (feature: string) => {
    let permissions = new Entity(feature).permissions.map(
      (permission: any) => permission.value
    );
    const contains = permissions.every((element: any) => {
      return rolesForm.values.rolePermissions.indexOf(element) !== -1;
    });
    if (contains) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Table className="permissionTable">
        <TableBody>
          {features.map((feature: any, index: number) => {
            const entity = new Entity(feature);
            return (
              <TableRow
                key={index}
                className={`tableRow ${
                  isChecked(feature) === true &&
                  rolesForm?.values?.name === "Owner"
                    ? "disabledGrid"
                    : ""
                }`}
              >
                <TableCell align="left" className="permissionTableCell">
                  <InputLabel className="permissionLabel" htmlFor={feature}>
                    {feature}
                  </InputLabel>
                </TableCell>
                <TableCell align="left" className="permissionTableCell">
                  {entity?.permissions.map((permission: any, index: number) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          className="checkBox"
                          id={`${feature}-${permission}`}
                          checkedIcon={<CheckBoxOutlined />}
                          disableRipple
                          value={permission?.value}
                          name={"rolePermissions"}
                          checked={rolesForm?.values?.rolePermissions?.includes(
                            permission?.value
                          )}
                          readOnly={rolesForm?.values?.rolePermissions?.includes(
                            permission?.value
                          )}
                          onChange={(e) => {
                            if (e.currentTarget.checked) {
                              rolesForm.setValues((prevData: any) => {
                                return {
                                  ...prevData,
                                  rolePermissions: [
                                    ...new Set([
                                      ...prevData.rolePermissions,
                                      permission?.value,
                                      ...entity?.getAssociatedReadPermissions(
                                        permission?.name
                                      ),
                                    ]),
                                  ],
                                };
                              });
                            } else {
                              if (e.currentTarget.checked === false) {
                                //
                              }
                              rolesForm?.handleChange(e);
                            }
                          }}
                        />
                      }
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "14px",
                        },
                      }}
                      label={permission.name}
                    />
                  ))}
                </TableCell>
                <TableCell align="right" className="permissionTableCell">
                  <FormControlLabel
                    control={
                      <Checkbox
                        className="selectAll"
                        checkedIcon={<CheckBoxOutlined />}
                        disableRipple
                        checked={isChecked(feature)}
                        onChange={(e) => {
                          handleSelectAll(entity, e.currentTarget.checked);
                        }}
                      />
                    }
                    labelPlacement="start"
                    label={<span className="checkBoxLabel">Select All</span>}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
export default Permissions;
