/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  styled,
  Container,
  Grid,
  InputBase,
  Divider,
} from "@mui/material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import HeaderUserbox from "./Userbox";
import { SidebarContext } from "../../../contexts/SidebarContext";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import "./styles.css";
import ZsBreadcrumbs from "../../../shared/components/breadcrumbs";
import { useLocation } from "react-router-dom";
import { searchPlaceHolder } from "../../../shared/utils/searchPlaceholder";
import { EntityContext } from "../../../contexts/EntityContext";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "var(--zs-white)",
  marginLeft: 0,
  width: "75%",
  height: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-input": {
    color: "var(--zs-grey)",
    paddingLeft: `calc(1em + ${theme.spacing(1)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      "&:focus": {},
    },
  },
}));

function Header() {
  const location = useLocation();
  const searchBoxReference: any = useRef();
  const { sidebarToggle, toggleSidebar, payload, setPayload } =
    useContext(SidebarContext);
  const { entity } = useContext(EntityContext);

  const handleSearch = () => {
    setPayload((prevData: any) => {
      return {
        ...prevData,
        skip: 0,
        search: searchBoxReference?.current?.value.trim(),
      };
    });
  };

  const handleClear = () => {
    setPayload((prevData: any) => {
      return {
        ...prevData,
        skip: 0,
        search: "",
      };
    });
    searchBoxReference.current.value = "";
  };

  useEffect(() => {
    if (searchPlaceHolder[location.pathname]) handleClear();
  }, [location]);

  return (
    <Container className="headerContainer">
      <Grid container className="subContainer">
        <Grid item xs={2} className="dashboardText">
          {entity.entityTitle}
        </Grid>
        <Grid item xs={8} className="searchContainer">
          {searchPlaceHolder[location.pathname] ? (
            <Search className="searchIcon">
              <StyledInputBase
                id="search"
                placeholder={
                  searchPlaceHolder[location.pathname] ?? "Search here"
                }
                inputProps={{ "aria-label": "search" }}
                inputRef={searchBoxReference}
                autoComplete="off"
                onKeyDown={(e) => {
                  if (e?.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              {payload?.search && searchBoxReference?.current?.value && (
                <IconButton size="medium" onClick={handleClear}>
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton size="medium" onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </Search>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={2} className="userContainer">
          <HeaderUserbox />
          <Box>
            <Box
              component="span"
              sx={{
                ml: 2,
                display: { lg: "none", xs: "inline-block" },
              }}
            >
              <Tooltip arrow title="Toggle Menu">
                <IconButton color="primary" onClick={toggleSidebar}>
                  {!sidebarToggle ? (
                    <MenuTwoToneIcon fontSize="small" />
                  ) : (
                    <CloseTwoToneIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <ZsBreadcrumbs />
    </Container>
  );
}

export default Header;
