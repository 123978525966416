/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";
import { EntityContext } from "../../../../contexts/EntityContext";
import useQuery from "../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import Loader from "../../../../shared/components/loader/linearProgress";
import PropertyDataTable from "../../../../shared/components/propertyDataTable";
import TitleBar from "../../../../shared/components/titleBar";
import NoPermissionContent from "../../../../shared/components/noPermissionBanner";

const PropertyList: FC = () => {
  const navigate = useNavigate();
  const { setEntityName } = useContext(EntityContext);
  const { payload, setPayload } = useContext(SidebarContext);
  const [status, setStatus] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [clearFilter, setClearFilter] = useState(false);
  // const [propertyData, setPropertyData] = useState("");
  // const [dateRange, setDateRange] = useState<DateRangeData>({
  //   from: null,
  //   to: null,
  // });
  // const [filterData, setFilterData] = useState<any>({
  //   startDate: null,
  //   endDate: null,
  // });
  const navigateToDetails = () => {
    setEntityName("Property");
    navigate("/property/create");
  };
  useEffect(() => {
    fetchData();
  }, [clearFilter, statusFilter]);
  const { loading, data, fetchData, error } = useQuery("/admin/properties", {
    params: {
      ...payload,
      filter: JSON.stringify({ status: statusFilter }),
    },
  });

  const handleStatusFilterChange = (e: any) => {
    setStatusFilter(e.target.value);
    setStatus(!status);
  };

  const handleFilterData = (e: any) => {
    if (statusFilter) {
      fetchData();
    }
  };

  const handleClearFilter = () => {
    setClearFilter(true);
    // fetchData();
  };

  // const {
  //   loading: filterLoading,
  //   data: filteredData,
  //   fetchData: filterFetch,
  // } = useQuery(`/admin/properties`, {
  //   params: {
  //     ...payload,
  //     filter: JSON.stringify({ status: statusFilter }),
  //   },
  // });

  const propertyDisplayColumns = ["title", "propertyId", "status"];

  // const properties = (data as any)?.items?.map((property: any) => {
  //   return {
  //     ...property,
  //     name: property?.title,
  //     id: property?.id,
  //     status: property?.status,
  //   };
  // });

  const properties = (data as any)?.items?.map((property: any) => {
    return {
      ...property,
      title: `${property?.title}`,
      propertyId: property?.id,
      status: property?.status,
    };
  });

  return (
    <>
      <Container className="propertiesTableContainer">
        <TitleBar
          title="Property"
          buttonTitle="Create"
          headerTitle="Property"
          onClick={navigateToDetails}
        />
        {loading ? (
          <Loader />
        ) : (error as any)?.statusCode === 403 ? (
          <NoPermissionContent />
        ) : (
          <>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={4}>
                <DateRangePicker
                  // label="Period"
                  onChange={(newValue: any) => {
                    setDateRange(newValue);
                    if (newValue) {
                      setFilterData((prev: any) => ({
                        ...prev,
                        startDate: formatDateToString(newValue?.from),
                        endDate: formatDateToString(newValue?.to),
                      }));
                    }
                  }}
                  setDateRange={setDateRange}
                  setFilterData={setFilterData}
                  dateRange={dateRange}
                  placeholder="DD/MM/YYYY-DD/MM/YYYY"
                />
              </Grid> */}

              <Grid className="filterComp" item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={"Status"}
                    fullWidth
                    value={statusFilter}
                    onChange={(e) => {
                      handleStatusFilterChange(e);
                    }}
                  >
                    <MenuItem value={"Pending Review"}>Pending Review</MenuItem>
                    <MenuItem value={"Draft"}>Draft</MenuItem>
                    <MenuItem value={"Published"}>Published</MenuItem>
                    {/* {propertyStatus?.map((status: any, index: number) => (
                      <MenuItem key={index} value={status?.status}>
                        {status?.status}
                      </MenuItem>
                    ))} */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid className="filterComp" item xs={12} md={3}>
                <Box display="flex" justifyContent="space-between">
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      handleFilterData(e);
                    }}
                  >
                    Filter
                  </Button>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      await setStatusFilter("");
                      handleClearFilter();
                    }}
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <PropertyDataTable
              entityName="Property"
              data={properties}
              displayColumns={propertyDisplayColumns}
              linkColum="name"
              linkUrl="/property"
              pagination={{ take: payload.take, skip: payload.skip }}
              setPagination={setPayload}
              totalItems={(data as any)?.totalItems}
              deleteApiUrl="/admin/properties"
              refetchFunction={fetchData}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default PropertyList;
