import Property from "../components/property";
import PropertyList from "../components/propertyList";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
const propertyRoute = [
  {
    path: "property",
    element: <PropertyList />,
    name: "Property",
    index: 1,
    link: "property",
    icon: HomeWorkIcon,
  },
  {
    path: "property/create",
    element: <Property />,
  },
  {
    path: "property/:id",
    element: <Property />,
  },
];
export default propertyRoute;
