import DashboardIcon from "@mui/icons-material/Dashboard";
import Dashboard from "../components/dashboard";

const dashboardRoute = [
  {
    path: "dashboard",
    element: <Dashboard />,
    index: 0,
    name: "Dashboard",
    link: "dashboard",
    icon: DashboardIcon,
  },
];
export default dashboardRoute;
