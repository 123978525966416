/* eslint-disable array-callback-return */
const mergeArrays = (array1: [any], array2: [any]) => {
  const itemsToAppend = array2?.filter(
    (item2) => !array1?.some((item1) => item1["name"] === item2["name"])
  );
  return [...array1, ...itemsToAppend];
};

const sortMenuItems = (menuItems: any) => {
  const sortedMenuItems: any = [];
  menuItems.map((menuItem: any) => {
    if (menuItem?.name) {
      // const index = sortedMenuItems?.findIndex(
      //   (sortedMenuItem: any) => sortedMenuItem?.name === menuItem?.name
      // );
      // if (index !== -1) {
      //   sortedMenuItems?.splice(menuItem?.order, 0, menuItem);
      //   // sortedMenuItems?.push(menuItem);
      // } else {
      //   sortedMenuItems?.push(menuItem);
      // }
      if (menuItem?.index >= 0) {
        if (sortedMenuItems[menuItem?.index]) {
          sortedMenuItems?.splice(menuItem?.index, 0, menuItem);
        } else {
          sortedMenuItems[menuItem?.index] = menuItem;
        }
      } else {
        sortedMenuItems?.push(menuItem);
      }
    }
  });
  return sortedMenuItems;
};

export const mergeMenuItems = (menuItems: any, sortItems?: boolean) => {
  const mergedMenuItems: any[] = [];
  let sortedMenuItems: any[] = [];
  menuItems?.forEach((menuItem: any) => {
    const existingMenuItem = mergedMenuItems?.find(
      (mergedMenuItem) =>
        mergedMenuItem?.name?.toUpperCase() === menuItem?.name?.toUpperCase()
    );

    if (existingMenuItem) {
      if (existingMenuItem.children || menuItem?.children)
        existingMenuItem.children = mergeArrays(
          existingMenuItem?.children,
          menuItem?.children
        );
      else mergedMenuItems?.push(menuItem);
    } else {
      mergedMenuItems?.push(menuItem);
    }
  });

  if (sortItems) {
    sortedMenuItems = sortMenuItems(mergedMenuItems);
    sortedMenuItems = sortedMenuItems?.map((menuItem) => ({
      ...menuItem,
      children: menuItem.children && sortMenuItems(menuItem?.children),
    }));
  }

  return [{ items: sortItems ? sortedMenuItems : mergedMenuItems }];
};
