import { Box, LinearProgress } from "@mui/material";
import "./styles.css";
import { FC } from "react";
const Loader: FC = () => {
  return (
    <Box className="loaderContainer">
      <LinearProgress />
    </Box>
  );
};

export default Loader;
