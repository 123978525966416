export const searchPlaceHolder: any = {
  "/admin": "Search by first name, last name, email or phone number",
  "/property": "Search by property name, location or property Id",
  "/customer": "Search by first name, last name, email or phone number",
  // "/settings/country": "Search by country name, code or currency code",
  // "/settings/city": "Search by name or country",
  "/settings/role": "Search by role name",
  // "/service": "Search by service name",
  // "/settings/tax-category": "Search by tax-category name",
  // "/settings/tax-rate": "Search by tax rate name, tax category or zone",
  // "/email-log": "Search by purpose or to address",
  // "/settings/payment-method": "Search by name or code",
  // "/subscription": "Search by subscription name",
  // "/settings/address": "Search by name",
};
