/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
// import request from "../../../request";
import { SnackBarAlertContext } from "../../../contexts/SnackbarContext";
import { ErrorResponse, handleError } from "../../utils/errorHandler";
import request from "../../../request";

// const request = customAxios("application/json");

const useMutation = (reDirectTo?: string) => {
  const snackBarAlertContext = useContext(SnackBarAlertContext);

  const navigate = useNavigate();
  const [response, setResponse] = useState({
    loading: false,
    error: null,
  });

  const generateApiCall = async (
    endPoint: string,
    requestType: "post" | "put" | "patch" | "delete",
    variables?: any
  ) => {
    switch (requestType) {
      case "post":
        return request.post(endPoint, variables);
      case "put":
        return request.put(endPoint, variables);
      case "patch":
        return request.patch(endPoint, variables);
      case "delete":
        return request.delete(endPoint, variables);
    }
  };

  const modifyData = useCallback(
    async (
      endPoint: string,
      requestType: "post" | "put" | "patch" | "delete",
      variables?: any,
      formInstance?: any
    ) => {
      try {
        setResponse({
          loading: true,
          error: null,
        });

        let apiResponse = await generateApiCall(
          endPoint,
          requestType,
          variables
        );

        if (response) {
          setResponse({
            loading: false,
            error: null,
          });
          snackBarAlertContext.set({
            status: true,
            type: "success",
            message: apiResponse?.data?.message,
          });
          if (reDirectTo) {
            navigate(reDirectTo);
          }
          return apiResponse?.data;
        }
      } catch (error: any) {
        setResponse({ loading: false, error: error });
        if (formInstance) {
          handleError(
            formInstance,
            error as ErrorResponse,
            snackBarAlertContext
          );
        } else {
          snackBarAlertContext.set({
            status: true,
            type: "error",
            message: error.message,
          });
        }
      }
    },
    []
  );

  return { ...response, modifyData };
};

export default useMutation;
