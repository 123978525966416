/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
// import Logo from "../../../shared/components/logo";
import Logo from "../../../shared/logo";
import { Box, Drawer, Hidden } from "@mui/material";
import { styled } from "@mui/material/styles";
import SidebarMenu from "./SidebarMenu";
import { SidebarContext } from "../../../contexts/SidebarContext";
import useQuery from "../../../shared/components/hooks/useQuery";

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
    width: ${theme.sidebar.width};
    color: ${theme.sidebar.textColor};
    background: ${theme.sidebar.background};
    box-shadow: ${theme.sidebar.boxShadow};
    height: 100%;
    overflow-y: hidden;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
      position: fixed;
      z-index: 10;
    }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
    display: flex;
    height: 88px;
    align-items: center;
    margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    border-bottom: solid 1px var(--zs-border) ;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { data: userData, fetchData } = useQuery("admin/roles/permissions");

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Logo />
            </TopSection>
            <SidebarMenu userData={(userData as any)?.data} />
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Logo />
              </TopSection>
              <SidebarMenu userData={(userData as any)?.data} />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
