/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { Container } from "@mui/material";
import { FC, useContext } from "react";
import DataTable from "../../../../../shared/components/dataTable";
import TitleBar from "../../../../../shared/components/titleBar";
import { useNavigate } from "react-router-dom";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Loader from "../../../../../shared/components/loader/linearProgress";
// import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import { EntityContext } from "../../../../../contexts/EntityContext";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";

const RoleList: FC = () => {
  const navigate = useNavigate();
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData, error } = useQuery("/admin/roles", {
    params: {
      ...payload,
      role: "all",
    },
  });
  const { setEntityName } = useContext(EntityContext);

  const navigateToDetails = () => {
    setEntityName({
      entityName: ["Create New Role"],
      entityTitle: "Role",
    });
    navigate("/settings/role/create");
  };

  const roleDisplayColumns = ["name", "enabled"];

  return (
    <Container className="tableListContainer">
      <TitleBar
        title="Role"
        buttonTitle="Create"
        onClick={navigateToDetails}
        headerTitle="Role"
        disabled={
          (data as any)?.entityPermissions
            ? !(data as any)?.entityPermissions?.includes("Role-Create")
            : false
        }
      />
      {loading ? (
        <Loader />
      ) : (error as any)?.statusCode === 403 ? (
        <NoPermissionContent />
      ) : (
        <DataTable
          entityName="Role"
          data={(data as any)?.items ?? []}
          displayColumns={roleDisplayColumns}
          linkColum="name"
          linkUrl="/settings/role"
          pagination={{ take: payload.take, skip: payload.skip }}
          setPagination={setPayload}
          totalItems={(data as any)?.totalItems}
          deleteApiUrl="/admin/roles"
          // disabled={
          //   (data as any)?.entityPermissions
          //     ? !(data as any)?.entityPermissions?.includes("Role-Delete")
          //     : false
          // }
          refetchFunction={fetchData}
        />
      )}
    </Container>
  );
};

export default RoleList;
