import { List } from "@mui/material";
import { useLocation, matchPath } from "react-router-dom";
import SidebarMenuItem from "./item";
import { styled } from "@mui/material/styles";
import { useContext, useState } from "react";
import { mergeMenuItems } from "./helpers";
import { SidebarContext } from "../../../../contexts/SidebarContext";

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  children?: any[];
  name: string;
}

const SubMenuWrapper = styled(List)(
  ({ theme }) => `
  
    &.MuiList-root {
      padding: 0;
        font-weight: normal !important;
      }
      .MuiListItem-root {
        width: 100% !important;
        padding: 2px ${theme.spacing(2)};
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
           background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(14)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
          position: relative;
          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};
            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};
            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }
          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }
          &.Mui-active{
            width: 100% !important;
            align-self: flex-start !important;
            background-color:var(--zs-activeButton-bg);     
            color:var(--zs-last-breadCrumb);
            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          },
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};
            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
            border-bottom:1px solid var(--zs-menuSeparation);
            width:100% !important;
          }

          .MuiListItem-root {
            
            padding: 1px ${theme.spacing(0)};
            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 4.5)};
            
              &.Mui-active,
              
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path,
  openMenu,
  setOpenMenu,
  userData,
}: {
  items: any;
  userData: any;
  path: string;
  openMenu: string[];
  setOpenMenu: Function;
}): JSX.Element => (
  <SubMenuWrapper key={items.path}>
    {items?.reduce(
      (ev: any, item: any) =>
        reduceChildRoutes({
          ev,
          item,
          path,
          openMenu,
          setOpenMenu,
          userData,
        }),
      []
    )}
  </SubMenuWrapper>
);

const reduceChildRoutes = ({
  ev,
  path,
  item,
  openMenu,
  setOpenMenu,
  userData,
}: {
  ev: JSX.Element[];
  path: string;
  userData: any;
  item: MenuItem;
  openMenu: string[];
  setOpenMenu: Function;
}): Array<JSX.Element> => {
  if (item.name) {
    const key = item?.name;
    const array = [];
    const exactMatch = item?.link
      ? !!matchPath(
          {
            path: item?.link,
            end: false,
          },
          path
        )
      : false;
    if (item.children) {
      const partialMatch = item?.link
        ? !!matchPath(
            {
              path: item?.link,
              end: false,
            },
            path
          )
        : false;
      array.push(key);
      if (
        userData?.userType === "SuperAdmin" ||
        item?.children?.some((childe) =>
          userData?.role?.permissions?.includes(`${childe?.name}-Read`)
        )
      ) {
        ev.push(
          <SidebarMenuItem
            key={key}
            active={partialMatch}
            open={partialMatch}
            name={item?.name}
            icon={item?.icon}
            link={item?.link}
            badge={item?.badge}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            menus={item}
          >
            {renderSidebarMenuItems({
              path,
              items: item?.children,
              openMenu,
              setOpenMenu,
              userData,
            })}
          </SidebarMenuItem>
        );
      }
    } else {
      if (
        userData?.userType === "SuperAdmin" ||
        userData?.role?.permissions?.includes(`${item?.name}-Read`)
      ) {
        ev.push(
          <SidebarMenuItem
            key={key}
            active={exactMatch}
            name={item?.name}
            link={item?.link}
            badge={item?.badge}
            icon={item?.icon}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            menus={item}
          />
        );
      }
    }
  }
  return ev;
};

function SidebarMenu(props: any) {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<any>([]);
  const { routes } = useContext(SidebarContext);
  const sideMenuItems = mergeMenuItems(routes, true);
  return (
    <>
      {sideMenuItems.map((menuItem: any, index: any) => (
        <span key={index}>
          {renderSidebarMenuItems({
            items: menuItem.items,
            path: location.pathname,
            openMenu,
            setOpenMenu,
            userData: props?.userData,
          })}
        </span>
      ))}
    </>
  );
}

export default SidebarMenu;
