import "./styles.css";
import { Box, Grid, Container } from "@mui/material";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
// import LoginImage from "./images/login.svg";
import InputTextfield from "../../../../shared/components/textField";
import { getValidationSchema } from "../../../../shared/validations/schema";
// import Logo from "../../../../shared/components/logo";
import useMutation from "../../../../shared/components/hooks/useMutation";
import LogoStartup from "../../../../shared/loginLogo";
const App = () => {
  const { loading, modifyData } = useMutation();

  const textFields = [
    {
      id: "email",
      name: "email",
      label: "Email Address",
      placeholder: "Enter your email address",
      type: "text",
    },
  ];

  const forgotPasswordForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: getValidationSchema(["email"]),
    onSubmit: async (formData: any) => {
      const response = await modifyData(
        `/admin/forgot-password`,
        "post",
        formData
      );
      if (response) forgotPasswordForm.resetForm();
    },
  });

  return (
    <Container className="forgotPasswordContainer">
      <Grid>
        <Box className="forgotPasswordLogo">{/* <Logo /> */}</Box>
        <Box>
          <Grid container>
            <Grid item xs={6} className="forgotPasswordGridContainer">
              {/* <img src={LoginImage} alt="forgotPasswordImage" /> */}
              <LogoStartup />
            </Grid>
            <Grid item xs={6} className="forgotPasswordGridContainer">
              <form
                onSubmit={forgotPasswordForm.handleSubmit}
                autoComplete="off"
                className="forgotPasswordForm"
              >
                <InputTextfield
                  className="mediumInputBox"
                  form={forgotPasswordForm}
                  fieldProps={textFields[0]}
                />
                <Grid container>
                  <Grid item md={12}>
                    <LoadingButton
                      className="signInButton"
                      loading={loading}
                      variant="contained"
                      type="submit"
                      fullWidth
                      size="small"
                    >
                      Forgot Password
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default App;
