/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Divider, Grid, TextField } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import ToggleSwitch from "../../../../../shared/components/switch";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { matchIsValidTel } from "mui-tel-input";
import TelePhoneInputField from "../../../../../shared/components/telephoneInput";
// import currencyList from "../../../../shared/json/currency.json";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { Description } from "@mui/icons-material";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};
const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const AnalysisSettings: FC = () => {
  const { id } = useParams();
  const { payload, setPayload } = useContext(SidebarContext);
  const [analysisData, setAnalysisData] = useState([{}]);

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/settings/analytics`,
    "/analytics"
  );

  const { modifyData } = useMutation(`/settings/analysisSettings`);
  useEffect(() => {
    fetchData();
  }, []);
  // useEffect(() => {
  //   if ((data as any)?.length) {
  //     console.log("in if loop");

  //     setAnalysisData(
  //       (data as any)?.analyticsMenu?.map((menuItem: any) => {
  //         return {
  //           ...menuItem,
  //         };
  //       })
  //     );
  //   }
  // }, [data]);

  const settingsForm = useFormik({
    initialValues: {
      analyticsMenu: (data as any)?.analyticsMenu
        ? (data as any)?.analyticsMenu
        : [{ name: "", description: "", code: "" }],
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(["analyticsMenu"]),
    validate: (values: any) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (formData: any) => {
      modifyData(
        `/admin/settings/analytics`,
        "post",
        {
          ...formData,
        },
        settingsForm
      );
    },
  });

  const handleNameChange = (e: any, code: string, itemIndex: number) => {
    settingsForm.setValues((prevData: any) => {
      const analyticsMenu = prevData?.analyticsMenu?.map(
        (item: any, index: number) => {
          if (index === itemIndex) {
            item = {
              ...item,
              name: e,
            };
          }
          return item;
        }
      );
      return {
        ...prevData,
        analyticsMenu,
      };
    });
  };
  const handleDescChange = (e: any, code: string, itemIndex: number) => {
    settingsForm.setValues((prevData: any) => {
      const analyticsMenu = prevData?.analyticsMenu?.map(
        (item: any, index: number) => {
          if (index === itemIndex) {
            item = {
              ...item,
              description: e,
            };
          }
          return item;
        }
      );
      return {
        ...prevData,
        analyticsMenu,
      };
    });
  };
  console.log("settingsForm", settingsForm.values);

  const titleBar = {
    title: "Update Analytics Menu",
    buttonTitle: "Update",
    headerTitle: "Analytics",
    onClick: () => settingsForm.handleSubmit(),
    dirty: settingsForm.dirty,
    disabled: Object.keys(settingsForm?.errors).length !== 0,
    buttonLoading: loading,
  };

  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <>
        <Grid container spacing={0}>
          <Grid xs={8}></Grid>
        </Grid>
        <br />
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
          {settingsForm?.values?.analyticsMenu?.map(
            (analyticsData: any, index: number) => {
              return (
                <>
                  <Grid item xs={3}>
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      value={
                        settingsForm?.values?.analyticsMenu[index]?.name ?? ""
                      }
                      onChange={(e) => {
                        handleNameChange(
                          e.target.value,
                          analyticsData.code,
                          index
                        );
                      }}
                      // onChange={(e: any, index, analyticsData.code) => {
                      //   settingsForm?.setFieldValue(
                      //     `analyticsMenu.$[{analyticsMenu.name}`],
                      //     e.target.value
                      //   );
                      // }}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Textarea
                      aria-label="minimum height"
                      minRows={2}
                      placeholder="Description"
                      // value={analyticsData.description}
                      value={
                        settingsForm?.values?.analyticsMenu[index]
                          ?.description ?? ""
                      }
                      onChange={(e) => {
                        handleDescChange(
                          e.target.value,
                          analyticsData.code,
                          index
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      sx={{ display: "none" }}
                      fullWidth
                      id="outlined-basic"
                      label="Title1"
                      variant="outlined"
                      value={
                        settingsForm?.values?.analyticsMenu[index]?.code ?? ""
                      }
                    />
                  </Grid>
                  <Divider />
                </>
              );
            }
          )}{" "}
        </Grid>
      </>

      {/* {id && <MemberHistory />} */}
    </FormContainer>
  );
};
export default AnalysisSettings;
