/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import { FC, useEffect } from "react";
import ToggleSwitch from "../../../../shared/components/switch";
import InputTextfield from "../../../../shared/components/textField";
import useMutation from "../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../shared/validations/schema";
import useLazyQuery from "../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../shared/components/formContainer";
import { matchIsValidTel } from "mui-tel-input";
import TelePhoneInputField from "../../../../shared/components/telephoneInput";

const Customer: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation("/customer");

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/customers/${id}`,
    "/customer"
  );

  const handleDOBChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "");
    let dayPart = value.slice(0, 2);
    let monthPart = value.slice(2, 4);

    if (dayPart === "00") dayPart = "01";
    if (monthPart === "00") monthPart = "01";

    if (parseInt(dayPart, 10) > 31) dayPart = "31";
    if (parseInt(monthPart, 10) > 12) monthPart = "12";

    if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
    else if (
      ["04", "06", "09", "11"].includes(monthPart) &&
      parseInt(dayPart, 10) > 30
    )
      dayPart = "30";
    value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
    if (e.target.value.charAt(e.target.value.length - 1) === "/") {
      value = dayPart;
    }
    adminForm.setFieldValue("dob", value);
  };

  const textFields = [
    {
      id: "admin-firstName",
      name: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "Enter first name",
      required: true,
    },
    {
      id: "admin-lastName",
      name: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "Enter last name",
      required: true,
    },
    {
      id: "admin-email",
      name: "email",
      label: "Email Address",
      type: "text",
      placeholder: "Enter email address",
      required: true,
    },
    {
      id: "admin-userName",
      name: "userName",
      label: "Username",
      type: "text",
      placeholder: "Enter your username",
      required: true,
    },
    {
      id: "admin-nickName",
      name: "nickName",
      label: "Nickname",
      type: "text",
      placeholder: "Enter your nickname",
      required: false,
    },
    {
      id: "admin-website",
      name: "website",
      label: "Website",
      type: "text",
      placeholder: "Enter website",
      required: true,
    },
  ];
  const adminForm = useFormik({
    initialValues: {
      firstName: (data as any)?.firstName ?? "",
      lastName: (data as any)?.lastName ?? "",
      email: (data as any)?.email ?? "",
      phoneNumber: (data as any)?.phone ?? null,
      phone: (data as any)?.phone ? `+${(data as any)?.phone}` : null,
      enabled: (data as any)?.enabled ?? true,
      enable2Fa: (data as any)?.user?.enable2Fa ?? false,
      dob: (data as any)?.dob ?? null,
      userName: (data as any)?.userName ?? "",
      nickName: (data as any)?.nickName ?? "",
      website: (data as any)?.website ?? "",
      sendUserNotification: (data as any)?.sendUserNotification ?? true,
      enableBilling: (data as any)?.user?.enableBilling ?? true,
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema([
      "firstName",
      "lastName",
      "email",
      "address",
      "companyName",
      "companyRegistrationNo",
      "dob",
      "userName",
      "nickName",
      "website",
      "sendUserNotification",
      "enableBilling",
    ]),
    validate: (values: any) => {
      const errors: any = {};
      if (values?.phoneNumber && matchIsValidTel(values?.phone) === false) {
        errors.phone = "Invalid Phone Number";
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      if (!formData.dob) {
        formData.dob = null;
      }
      if (!formData.phoneNumber) {
        formData.phone = null;
      }
      if (id) {
        modifyData(
          `/admin/customers/${id}`,
          "put",
          {
            ...formData,
          },
          adminForm
        );
      } else {
        modifyData(
          `/admin/customers`,
          "post",
          {
            ...formData,
          },
          adminForm
        );
      }
    },
  });

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  const titleBar = {
    title: id
      ? `${(data as any)?.firstName ?? ""} ${(data as any)?.lastName ?? ""}`
      : "Create New Customer",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Customer",
    onClick: () => adminForm.handleSubmit(),
    dirty: adminForm.dirty,
    disabled: Object.keys(adminForm?.errors).length !== 0,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <>
        <Grid container spacing={0}>
          <Grid xs={6}></Grid>
          <Grid xs={2}>
            <ToggleSwitch
              status={adminForm?.values?.enable2Fa}
              label="Enable2Fa"
              handleChange={() => {
                adminForm?.setFieldValue(
                  "enable2Fa",
                  !adminForm?.values?.enable2Fa
                );
              }}
            />
          </Grid>
          <Grid xs={2}>
            <ToggleSwitch
              label="Enable"
              status={adminForm?.values?.enabled}
              handleChange={() => {
                adminForm?.setFieldValue(
                  "enabled",
                  !adminForm?.values?.enabled
                );
              }}
            />
          </Grid>
          <Grid xs={2}>
            <ToggleSwitch
              label="Enable Billing"
              status={adminForm?.values?.enableBilling}
              handleChange={() => {
                adminForm?.setFieldValue(
                  "enableBilling",
                  !adminForm?.values?.enableBilling
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
          {textFields.map((field, index: number) => (
            <Grid item xs={4} key={field.id}>
              <InputTextfield form={adminForm} fieldProps={field} key={index} />
            </Grid>
          ))}
          <Grid item xs={4}>
            <InputLabel className="dobLabel">Date of Birth</InputLabel>
            <TextField
              id="dob"
              name="dob"
              type="text"
              required={false}
              placeholder="DD/MM"
              onChange={handleDOBChange}
              value={adminForm?.values?.dob || ""}
              fullWidth
              error={Boolean(
                (adminForm.touched as any)?.dob &&
                  (adminForm.errors as any)?.dob
              )}
              helperText={
                (adminForm.touched as any)?.dob &&
                (adminForm.errors as any)?.dob
              }
              onBlur={adminForm.handleBlur}
            />
          </Grid>
          <Grid item xs={4} className="phoneTextfield">
            <TelePhoneInputField
              fieldProps={{
                id: "admin-phone",
                name: "phone",
                label: "Phone Number",
                placeholder: "Enter phone number",
              }}
              form={adminForm}
              detectCountryCode={id && (data as any)?.phone ? false : true}
              handleChange={(phone: string, info: any) => {
                adminForm.setValues((prevData: any) => {
                  return {
                    ...prevData,
                    phone,
                    phoneNumber: info.nationalNumber,
                  };
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  id="sendUserNotification"
                  name="sendUserNotification"
                  checked={adminForm?.values?.sendUserNotification}
                  onChange={(e) => {
                    adminForm.setFieldValue(
                      "sendUserNotification",
                      e.target.checked
                    );
                  }}
                />
              }
              label="Send User Notification?"
            />
          </Grid>
        </Grid>
      </>
      {/* {id && <MemberHistory />} */}
    </FormContainer>
  );
};
export default Customer;
