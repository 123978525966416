/* eslint-disable array-callback-return */
import * as yup from "yup";
import regEx from "./regularExpression";
import { formatFieldError } from "../utils/formatter";
const SUPPORTED_FORMATS = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
];

const validationSchema: any = {
  userName: yup
    .string()
    .test(
      "betweenSpaces",
      "The username has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(2, "Username must be more than 2 characters")
    .max(30, "Username cannot exceed more than 30 characters")
    .required("Username is a required field"),
  title: yup
    .string()
    .test(
      "betweenSpaces",
      "The title has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(2, "Title must be more than 2 characters")
    .max(30, "Title cannot exceed more than 30 characters")
    .required("Title is a required field"),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Wesite is a required field"),
  password: yup
    .string()
    .test(
      "betweenSpaces",
      "Password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(8, "Password must be more than 8 characters")
    .max(16, "Password cannot exceed more than 16 characters")
    .required("Password is a required field"),
  newPassword: yup
    .string()
    .required("New password is a required field")
    .test(
      "betweenSpaces",
      "New password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .matches(regEx.password, "New password is too weak"),
  confirmPassword: yup
    .string()
    .required("Confirm password is a required field")
    .test(
      "betweenSpaces",
      "Conform password has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .oneOf([yup.ref("newPassword")], "Confirm passwords must match"),
  firstName: yup
    .string()
    .test(
      "betweenSpaces",
      "First name has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(2, "First name must be more than 2 characters")
    .max(30, "First name cannot exceed more than 30 characters")
    .required("First name is a required field")
    .matches(regEx.alphabetsOnly, "First name can only contain alphabets"),
  lastName: yup
    .string()
    .matches(regEx.alphabetsOnly, "Last name can only contain alphabets")
    .test(
      "betweenSpaces",
      "Last name has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(1, "Last name must be more than 1 characters")
    .max(30, "Last name cannot exceed more than 30 characters")
    .required("Last name is a required field"),
  email: yup
    .string()
    .test(
      "betweenSpaces",
      "Email address has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .email("Invalid email address format")
    .matches(regEx.email, "Invalid email address format")
    .min(2, "Email address must be more than 2 characters")
    .max(30, "Email address cannot exceed more than 30 characters")
    .required("Email address is a required field"),
  address: yup
    .string()
    .matches(regEx.address, "Address can only contain alphabets and numbers")
    .test(
      "betweenSpaces",
      "Address has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .max(60, "Address cannot exceed more than 60 characters"),
  companyName: yup
    .string()
    .test(
      "betweenSpaces",
      "Company name has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .matches(
      regEx.alphabetsNumbers,
      "Company name can only contain alphabets and numbers"
    )
    .max(20, "Company name cannot exceed more than 20 characters"),
  dob: yup
    .string()
    .notRequired()
    .matches(regEx.dateMonth, "Date of birth must be in DD/MM format"),
  companyRegistrationNo: yup
    .string()
    .matches(
      regEx.alphabetsNumbers,
      "Company registration number can only contain alphabets and numbers"
    )
    .test(
      "betweenSpaces",
      "Company registration number has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .max(
      20,
      "Company registration number cannot exceed more than 20 characters"
    ),
  type: yup.string().required("Country type is required field"),
  fixedAmount: yup.boolean().required("Fixed amount is required field"),
  description: yup
    .string()
    .test(
      "betweenSpaces",
      "Description has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .nullable(),
  servicePrice: yup
    .number()
    .nullable()
    .min(1, "Price must be at least 1 character ")
    .required("Price is a required field")
    .typeError("Price must be a number")
    .positive("Price must be a positive number"),
  code: yup
    .string()
    .matches(
      regEx.alphabetsNumbers,
      "Code can only contain alphabets and numbers"
    )
    .test(
      "betweenSpaces",
      "Code has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(1, "Code must be more than 1 characters")
    .max(10, "Code cannot exceed more than 10 characters")
    .required("Code is a required field"),
  paymentType: yup.number().required("Payment type is a required field"),
  zip: yup
    .string()
    .min(1, "Zip must be at least 1 character ")
    .matches(
      regEx.alphabetsNumbers,
      "Zip can only contain alphabets and numbers"
    )
    .test(
      "betweenSpaces",
      "Zip has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    ),
  cityId: yup.string().required("City is a required field"),
  serviceTranslation: yup.array().of(
    yup.object({
      name: yup
        .string()
        .matches(regEx.alphabetsOnly, "Name can only contain alphabets")
        .test(
          "betweenSpaces",
          "Name has an excessive number of intermediate spaces",
          (value: any) => {
            if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        )
        .min(2, "Name must be more than 2 characters")
        .max(30, "Name cannot exceed more than 30 characters")
        .required("Name is a required field"),
    })
  ),
  subscriptionTranslation: yup.array().of(
    yup.object({
      paymentType: yup.string().required("Payment type is a required field"),
      name: yup
        .string()
        .matches(regEx.alphabetsOnly, "Name can only contain alphabets")
        .test(
          "betweenSpaces",
          "Name has an excessive number of intermediate spaces",
          (value: any) => {
            if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        )
        .min(2, "Name must be more than 2 characters")
        .max(30, "Name cannot exceed more than 30 characters")
        .required("Name is a required field"),
      duration: yup
        .string()
        .test("required", "Duration is a required field", (value) => {
          const splitArray = value?.split(" ");
          if (splitArray?.[0] && splitArray?.[1]) {
            return true;
          } else {
            return false;
          }
        }),
      addOnServiceDuration: yup.string().nullable(),
    })
  ),
  services: yup.array().min(1, "Services is a required field"),
  cities: yup.array().min(1, "City is a required field"),
  countryTranslation: yup.array().of(
    yup.object({
      name: yup
        .string()
        .test(
          "betweenSpaces",
          "Name has an excessive number of intermediate spaces",
          (value: any) => {
            if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        )
        .matches(regEx.alphabetsOnly, "Name can only contain alphabets")
        .required("Name is a required field"),
    })
  ),
  addressSubscriptions: yup.array().of(
    yup.object().shape({
      priceIncludeTax: yup
        .boolean()
        .required("Price include tax is a required field"),
      addressSubscriptionPrices: yup
        .array()
        .of(
          yup
            .object()
            .shape({
              tireOnePrice: yup
                .array()
                .of(
                  yup.object().shape({
                    price: yup
                      .number()
                      .required("Tire one price is a required field"),
                  })
                )
                .test(
                  "all-service-tier-one-price",
                  "please enter tier-one pricing for all services",
                  function (value, context) {
                    if (this?.parent?.serviceCount) {
                      return (
                        value?.filter((data) => data)?.length ===
                        context?.parent?.serviceCount
                      );
                    } else {
                      return true;
                    }
                  }
                ),
              tireTwoPrice: yup
                .array()
                .of(
                  yup.object().shape({
                    price: yup
                      .number()
                      .required("Tire two price is a required field"),
                  })
                )
                .test(
                  "all-service-tier-two-price",
                  "please enter tier-two pricing for all services",
                  function (value, context) {
                    if (this?.parent?.serviceCount) {
                      return (
                        value?.filter((data) => data)?.length ===
                        context?.parent?.serviceCount
                      );
                    } else {
                      return true;
                    }
                  }
                ),
            })
            .test(
              "is-two-keys",
              "please enter both tire one and tire two price",
              function (value) {
                if (value) {
                  return ["tireOnePrice", "tireTwoPrice"]?.every((i) =>
                    Object?.keys(value)?.includes(i)
                  );
                }
              }
            )
        )
        .min(1, "Subscription price is a required field"),
    })
  ),
  cityTranslation: yup.array().of(
    yup.object({
      name: yup
        .string()
        .matches(regEx.alphabetsOnly, "Name can only contain alphabets")
        .test(
          "betweenSpaces",
          "Name has an excessive number of intermediate spaces",
          (value: any) => {
            if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        )
        .max(30, "Name cannot exceed more than 30 characters")
        .required("Name is a required field"),
    })
  ),
  addressTranslation: yup.array().of(
    yup.object({
      buildingName: yup
        .string()
        .matches(
          regEx.alphabetsOnly,
          "Building name can only contain alphabets"
        )
        .test(
          "betweenSpaces",
          "Building name has an excessive number of intermediate spaces",
          (value: any) => {
            if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        )
        .min(1, "Building name must be more than 1 characters")
        .required("Building name is a required field"),
      addressLine1: yup
        .string()
        .matches(
          regEx.address,
          "Address line 1 can only contain alphabets and numbers"
        )
        .test(
          "betweenSpaces",
          "Address line 1 has an excessive number of intermediate spaces",
          (value: any) => {
            if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        )
        .min(1, "Address line 1 must be more than 1 characters")
        .required("Address line 1 is a required field"),
      addressLine2: yup
        .string()
        .matches(
          regEx.address,
          "Address line 2 can only contain alphabets and numbers"
        )
        .test(
          "betweenSpaces",
          "Address line 2 has an excessive number of intermediate spaces",
          (value: any) => {
            if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
              return false;
            } else {
              return true;
            }
          }
        )
        .min(1, "Address line 2 must be more than 1 characters")
        .required("Address line 2 is a required field"),
    })
  ),
  countryId: yup.string().required("Country is a required field"),
  currencyCode: yup
    .string()
    .test(
      "betweenSpaces",
      "Currency code has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .required("Currency is a required field"),
  name: yup
    .string()
    .matches(regEx.alphabetsOnly, "Name can only contain alphabets")
    .test(
      "betweenSpaces",
      "Name has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(2, "Name must be more than 2 characters")
    .max(30, "Name cannot exceed more than 30 characters")
    .required("Name is a required field"),
  rate: yup
    .number()
    .typeError("Rate must be a number")
    .required("Rate is a required field")
    .min(1, "Rate must be greater than 0"),
  taxCategory: yup.string().required("Tax category is a required field"),
  zone: yup.string().required("Zone is a required field"),
  roleId: yup.string().required("Role is a required field"),
  imageUrl: yup
    .array()
    .min(1, "Image is a required field")
    .required("Image is a required field"),
  fromAddress: yup
    .string()
    .required("From address is required")
    .matches(
      regEx.address,
      "From address can only contain alphabets and numbers"
    )
    .test(
      "betweenSpaces",
      "From address has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    ),
  subject: yup
    .string()
    .matches(regEx.address, "Subject can only contain alphabets and numbers")
    .test(
      "betweenSpaces",
      "Subject has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    ),
  date: yup.string(),
  time: yup.string(),
  file: yup
    .mixed()
    .required("File is a required field")
    .test("FILE_SIZE", "File is too big.Max size is 1Mb", (value: any) => {
      if (value && typeof value !== "string") {
        return value.size <= 1048576;
      } else return true;
    })
    .test("FILE_FORMAT", "File has unsupported format.", (value: any) => {
      if (value && typeof value !== "string") {
        return SUPPORTED_FORMATS.includes(value.type);
      } else return true;
    }),
  roleName: yup
    .string()
    .matches(regEx.alphabetsOnly, "Role can only contain alphabets")
    .test(
      "betweenSpaces",
      "Role has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .max(30)
    .required("Role is a required field"),
  rolePermissions: yup
    .array()
    .min(1, "You should select at least one permission")
    .required("Permissions is a required field"),
  selectedLanguages: yup.array().min(1, "Select at least one language"),
  defaultLanguage: yup.object().required("Select the default language"),
  selectedCurrencies: yup.array().min(1, "Select at least one currency"),
  defaultCurrency: yup.object().required("Select the default currency"),
  secretKey: yup
    .string()
    .test(
      "betweenSpaces",
      "Secret key has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .min(1, "Secret key must be more than 2 characters")
    .required("Secret key is a required field"),
  publicKey: yup
    .string()
    .test(
      "betweenSpaces",
      "Public key has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    )
    .test(
      "notSameAsSecretKey",
      "Public key must not be the same as Secret key",
      function (value) {
        return value !== this.parent.secretKey;
      }
    )
    .min(1, "Public key must be more than 2 characters")
    .required("Public key is a required field"),

  paymentMethodDescription: yup
    .string()
    .test(
      "betweenSpaces",
      "Payment method description has an excessive number of intermediate spaces",
      (value: any) => {
        if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
          return false;
        } else {
          return true;
        }
      }
    ),
  priceData: yup.array().of(
    yup.object({
      tireOnePrice: yup
        .string()
        .required("Tire one price is a required field")
        .min(1, "Tire one price must be greater than 0"),
      tireTwoPrice: yup
        .string()
        .required("Tire two price is a required field")
        .min(1, "Tire two price must be greater than 0"),
    })
  ),
  fields: yup.array().min(1, "You should add at least one field"),
};

export const getValidationSchema = (fields: string[]) => {
  const newValidationSchema: any = {};

  fields.map((fieldName) => {
    newValidationSchema[fieldName] = validationSchema[fieldName];
  });

  return yup.object().shape(newValidationSchema);
};

export const generateDynamicValidationSchema = (
  fields: string[],
  key1: string,
  key2: string
) => {
  const newValidationSchema: any = {},
    newObject: any = {};

  fields?.map((fieldName) => {
    const formattedFieldName = formatFieldError(fieldName);
    newObject[fieldName] = yup
      .string()
      .test(
        "betweenSpaces",
        `${formattedFieldName} has an excessive number of intermediate spaces`,
        (value: any) => {
          if (value?.trim()?.match(regEx.tooManySpace)?.length > 0) {
            return false;
          } else {
            return true;
          }
        }
      )
      .min(2, `${formattedFieldName} must be more than 2 characters`)
      .required(`${formattedFieldName} is a required field`);
  });

  newValidationSchema[key1] = yup
    .array()
    .of(yup.object({ [key2]: yup.object(newObject) }));

  return yup.object().shape(newValidationSchema);
};
