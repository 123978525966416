import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import "./styles.css";
import { FC, useEffect, useState } from "react";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PaginationComponent from "../pagination";
import NoDataFound from "../noDataFoundBanner";
import DeletePermissionDialog from "../deletePermissionModal";
import useMutation from "../hooks/useMutation";
import { Link, useNavigate } from "react-router-dom";
import ToggleSwitch from "../switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getLocalStorageData } from "../../utils/localStorage";
import SendIcon from "@mui/icons-material/Send";
import request from "../../../request";
type BlogDataTableProps = {
  data: Object[];
  entityName?: string;
  linkColum?: string;
  linkUrl?: string;
  pagination: any;
  setPagination: Function;
  displayColumns: string[];
  totalItems?: number;
  // deleteApiUrl?: string;
  refetchFunction?: Function;
};

const BlogDataTable: FC<BlogDataTableProps> = ({
  data,
  entityName,
  linkColum,
  linkUrl,
  pagination,
  setPagination,
  displayColumns,
  totalItems,
  // deleteApiUrl,
  refetchFunction = () => {},
}) => {
  const { modifyData } = useMutation(linkUrl);
  const [deletedRecord, setDeletedRecord] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [statusId, setStatusId] = useState<any>();
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const [mailLoader, setMailLoader] = useState<any>(null);
  const [statusMessage, setStatusMessage] = useState<any>();
  // const handleDelete = async () => {
  //   await modifyData(`${deleteApiUrl}/${deletedRecord.id}`, "delete");
  //   setDeletedRecord(null);
  //   refetchFunction();
  // };
  if (statusId) {
    modifyData(`/admin/properties/${statusId}/change-status`, "put", {
      status: statusMessage,
    });
    setStatusId("");
    refetchFunction();
  }
  const booleanStatus: any = {
    verified: {
      true: "Verified",
      false: "Not Verified",
    },
    status: {
      true: "Success",
      false: "Failed",
    },
    enabled: {
      true: "Enabled",
      false: "Disabled",
    },
  };

  return (
    <>
      {data?.length > 0 ? (
        <>
          {/* {deletedRecord ? (
            <DeletePermissionDialog
              open={deletedRecord ? true : false}
              handleClose={() => {
                setDeletedRecord(null);
              }}
              deletedData={{
                name: linkColum ? deletedRecord[linkColum] : "",
                content: entityName ?? "",
              }}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )} */}
          {/* <Scrollbars autoHeight autoHeightMax="55vh"> */}
          <div className="scrollBar">
            <Table
              className={linkUrl ? "dataTable editableDataTable" : "dataTable"}
            >
              <TableHead>
                <TableRow className="headerRow">
                  <TableCell align="center" className="columnName">
                    Sl No
                  </TableCell>
                  {displayColumns.map((title, columnIndex: number) => {
                    return (
                      <TableCell
                        align="center"
                        className="columnName"
                        key={columnIndex}
                      >
                        {title === "createdAt"
                          ? "Date & Time"
                          : title.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </TableCell>
                    );
                  })}
                  {/* {deleteApiUrl ? (
                    <TableCell align="center" className="columnName">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )} */}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((dataItem: any, dataIndex: number) => (
                  <TableRow
                    key={dataIndex}
                    onClick={() => {
                      linkUrl && navigate(`${linkUrl}/${dataItem.id}`);
                    }}
                  >
                    <TableCell align="center" className="tableCell">
                      {pagination?.skip * pagination?.take + dataIndex + 1}
                    </TableCell>
                    {displayColumns.map((title, columnIndex: number) => {
                      return (
                        <TableCell
                          align="center"
                          className="tableCell"
                          key={columnIndex}
                        >
                          {linkColum === title ? (
                            <Link
                              className="link"
                              to={linkUrl + `/${dataItem.id}`}
                            >
                              {dataItem[title]}
                            </Link>
                          ) : typeof dataItem[title] == "boolean" ? (
                            <Chip
                              label={booleanStatus?.[title]?.[dataItem[title]]}
                              variant="outlined"
                              className={
                                dataItem[title] ? "chipEnabled" : "chipDisabled"
                              }
                            />
                          ) : title === "createdAt" ? (
                            new Date(dataItem[title]).toLocaleString()
                          ) : (
                            <span>
                              {dataItem[title]?.length > 35 ? (
                                <span>
                                  {dataItem[title].slice(0, 35)}
                                  <Tooltip title={dataItem[title]} arrow>
                                    <span className="readMore">
                                      {"....."}
                                      Read more
                                    </span>
                                  </Tooltip>
                                </span>
                              ) : (
                                dataItem[title]
                              )}
                            </span>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* </Scrollbars> */}
          </div>
        </>
      ) : (
        <NoDataFound />
      )}
      {/* <Grid className="paginationGrid">
        <PaginationComponent
          totalItems={totalItems}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Grid> */}
    </>
  );
};

export default BlogDataTable;
