/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  Tabs,
  Tab,
  Box,
  OutlinedInput,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import InputTextfield from "../../../../shared/components/textField";
import useMutation from "../../../../shared/components/hooks/useMutation";
// import { getValidationSchema } from "../../../../shared/validations/propertySchema";
import validationSchema from "../../../../shared/validations/propertySchema";
import useLazyQuery from "../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../shared/components/formContainer";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import Button from "@mui/material/Button";
import { ZSAutocomplete } from "../../../../shared/components/autoComplete";
import useQuery from "../../../../shared/components/hooks/useQuery";
import DeleteIcon from "@mui/icons-material/Delete";
import InputImageUpload from "../../../../shared/components/imageUpload";
import ToggleSwitch from "../../../../shared/components/switch";
import { ErrorMessage } from "../../../../shared/components/errorMessage";
import { getValidationSchema } from "../../../../shared/validations/schema";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import Editor from "ckeditor5-custom-build/";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Property: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation("/property");
  const { payload, setPayload } = useContext(SidebarContext);
  const [addLink, setaddLink] = useState<any>([{}]);
  const [status, setStatus] = useState(false);
  const [value, setValue] = useState(0);
  const [contact, setContact] = useState("");
  const [draftFlag, setDraftFlag] = useState(false);
  const { loading, data, fetchData } = useLazyQuery(
    `/admin/properties/${id}`,
    "/property"
  );

  const {
    loading: customerLoading,
    data: customerData,
    fetchData: fetchCustomerData,
  } = useQuery(
    "/admin/customers",
    {
      params: { ...payload, skip: 0, take: 15 },
    },
    "/property"
  );

  const propertyUsers =
    (data as any)?.users?.map((user: any) => {
      return {
        userId: user.id,
      };
    }) ?? [];

  const textFields = [
    {
      id: "property-title",
      name: "title",
      label: "Property Title",
      type: "text",
      placeholder: "Enter property title",
      required: true,
    },
  ];
  const reportSettingsFields = [
    {
      id: "property-iframe",
      name: "googleDataSudioIFrameSource",
      label: "Google Data Studios iframe Source",
      type: "text",
      placeholder: "Enter Google Data Studios iframe Source",
      required: false,
    },
    {
      id: "property-driveFolderId",
      name: "googleDriveFolderId",
      label: "Google Drive Folder ID",
      type: "text",
      placeholder: "Enter Google Drive Folder ID",
      required: false,
    },
    {
      id: "hotel-Id",
      name: "hotelId",
      label: "DS Hotel ID",
      type: "number",
      placeholder: "Enter Hotel ID",
      required: true,
    },
  ];
  const billingSettingsFields = [
    {
      id: "property-billingIframeUrl",
      name: "billingIFrameURL",
      label: "Billing iframe URL",
      type: "text",
      placeholder: "Enter Billing iframe URL",
      required: false,
    },
  ];
  const propertySettingsFields = [
    {
      id: "property-name",
      name: "name",
      label: "Property Name",
      type: "text",
      placeholder: "Enter Property Name",
      required: true,
    },
    {
      id: "property-message",
      name: "message",
      label: "Property Message",
      type: "text",
      placeholder: "Enter message",
      required: false,
      multiline: true,
      rows: 8,
      maxRows: 12,
    },
    {
      id: "Property-streetAddress",
      name: "addressLane1",
      label: "Property Street Address",
      type: "text",
      placeholder: "Enter Property Street Address",
      required: false,
    },
    {
      id: "Property-streetAddress-2",
      name: "addressLane2",
      label: "Property Street Address 2",
      type: "text",
      placeholder: "Enter Property Street Address 2",
      required: false,
    },
    {
      id: "Property-postalCode",
      name: "postalCode",
      label: "Property Postal Code",
      type: "text",
      placeholder: "Enter Property Postal Code",
      required: false,
    },
    {
      id: "Property-city",
      name: "city",
      label: "Property City",
      type: "text",
      placeholder: "Enter Property City",
      required: false,
    },
    {
      id: "Property-state",
      name: "state",
      label: "Property State",
      type: "text",
      placeholder: "Enter Property State",
      required: false,
    },
    {
      id: "Property-country",
      name: "country",
      label: "Property Country",
      type: "text",
      placeholder: "Enter Property Country",
      required: false,
    },
    {
      id: "Property-totalRooms",
      name: "totalRooms",
      label: "Total Rooms",
      type: "number",
      placeholder: "Enter Total Rooms",
      required: false,
    },
    {
      id: "Property-adr",
      name: "ADR",
      label: "ADR",
      type: "text",
      placeholder: "Enter ADR",
      required: false,
    },
    {
      id: "Property-bookingEngine",
      name: "boookingEngine",
      label: "Booking Engine",
      type: "text",
      placeholder: "Enter Booking Engine",
      required: false,
    },
    {
      id: "Property-bookingEngineUrl",
      name: "boookingEngineURL",
      label: "Booking Engine URL",
      type: "text",
      placeholder: "Enter Booking Engine URL",
      required: false,
    },
    {
      id: "Property-propertyUrl",
      name: "url",
      label: "Property URL",
      type: "text",
      placeholder: "Enter Property URL",
      required: false,
    },
    {
      id: "Property-tripAdvisorId",
      name: "tripAdvisorId",
      label: "TripAdvisor ID",
      type: "text",
      placeholder: "Enter TripAdvisor ID",
      required: false,
    },
  ];
  const createValidationSchema = [
    "title",
    "propertyDescription",
    "propertyIframe",
    "propertyDriveFolderId",
    "propertyBillingIframeUrl",
    "propertyName",
    "propertyMessage",
    "propertyStreetAddress",
    "propertyStreetAddress2",
    "propertyPostalCode",
    "propertyCity",
    "propertyState",
    "propertyCountry",
    "propertyTotalRooms",
    "propertyAdr",
    "hotelId",
    "propertyBookingEngine",
    "propertyBookingEngineUrl",
    "propertyPropertyUrl",
    "propertyTripAdvisorUrl",
  ];
  const createDraftSchema = ["title"];
  const propertyForm = useFormik({
    initialValues: {
      title: (data as any)?.title ?? "",
      status: (data as any)?.status ?? "",
      googleDataSudioIFrameSource:
        (data as any)?.googleDataSudioIFrameSource ?? "",
      googleDriveFolderId: (data as any)?.googleDriveFolderId ?? "",
      reportPdfUrl: (data as any)?.reportPdfUrl ?? "",
      billingIFrameURL: (data as any)?.billingIFrameURL ?? "",
      hotelId: Number((data as any)?.hotelId) ?? null,
      enablePropertyBilling: (data as any)?.enablePropertyBilling ?? true,
      enableAnalysis: (data as any)?.enableAnalysis ?? true,
      propertySetting: {
        name: (data as any)?.propertySetting?.name ?? "",
        message: (data as any)?.propertySetting?.message ?? "",
        addressLane1: (data as any)?.propertySetting?.addressLane1 ?? "",
        addressLane2: (data as any)?.propertySetting?.addressLane2 ?? "",
        postalCode: (data as any)?.propertySetting?.postalCode ?? null,
        city: (data as any)?.propertySetting?.city ?? "",
        state: (data as any)?.propertySetting?.state ?? "",
        country: (data as any)?.propertySetting?.country ?? "",
        totalRooms: (data as any)?.propertySetting?.totalRooms ?? null,
        ADR: (data as any)?.propertySetting?.ADR ?? "",
        boookingEngine: (data as any)?.propertySetting?.boookingEngine ?? "",
        boookingEngineURL:
          (data as any)?.propertySetting?.boookingEngineURL ?? "",
        url: (data as any)?.propertySetting?.url ?? "",
        tripAdvisorId: (data as any)?.propertySetting?.tripAdvisorId ?? "",
        phoneNumber: contact ?? null,
        phone: contact ?? null,
        // googleLogoUrl: (data as any)?.tripAdvisorId.googleLogoUrl,
        // tripAdvisorLogoUrl: (data as any)?.tripAdvisorId.tripAdvisorLogoUrl,
      },
      propertyUsers,
      propertyInvoices: addLink,
    },
    enableReinitialize: true,
    // validationSchema: getValidationSchema([
    //   "title",
    //   "name",
    //   "googleDataSudioIFrameSource",
    //   "hotelId",
    // ]),
    // validationSchema: validationSchema,
    // validate: (values: any) => {
    //   const errors: any = {};

    //   return errors;
    // },
    onSubmit: async (formData: any) => {
      if (id) {
        const url = draftFlag
          ? `/admin/properties/${id}/draft`
          : `/admin/properties/${id}/review`;
        modifyData(
          url,
          "put",
          {
            ...formData,
          },
          propertyForm
        );
        // setDraftFlag(false);
      } else {
        modifyData(
          `/admin/properties`,
          "post",
          {
            ...formData,
          },
          propertyForm
        );
      }
    },
  });
  console.log("err", propertyForm.values);

  useEffect(() => {
    if (id) fetchData();
    fetchCustomerData();
  }, [id]);
  useEffect(() => {
    if (!loading && data) {
      setaddLink((data as any)?.propertyInvoices);
    }
  }, [loading]);

  const titleBar = {
    title: id ? `${(data as any)?.title ?? ""}` : "Create New Property",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Property",
    onClick: () => propertyForm.handleSubmit(),
    dirty: propertyForm.dirty,
    disabled:
      Object.keys(propertyForm?.errors).length !== 0 ||
      propertyForm.values.title === "",
    buttonLoading: apiLoading,
  };

  const handleAddLink = () => {
    setaddLink([...addLink, { text: "", link: "" }]);
  };
  const setLinkText = (event: any, index: any) => {
    addLink[index].text = event.target.value;
    setaddLink(addLink);
    setStatus(!status);
  };
  const setLinkUrl = (event: any, index: any) => {
    addLink[index].link = event.target.value;
    setaddLink(addLink);
    setStatus(!status);
  };
  const handleRemoveLinkField = (index: any) => {
    const fieldList = [...addLink];
    if (Array.isArray(fieldList)) {
      fieldList.splice(index, 1);
    }
    setaddLink(fieldList);
  };
  const handleImageUpload = (files: string[]) => {
    propertyForm.setFieldValue("image", files);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleSaveDraft = () => {
    setDraftFlag(true);
    setStatus(!status);
    propertyForm.handleSubmit();
  };
  const customers = (customerData as any)?.items?.map((customer: any) => {
    return {
      id: customer.userId,
      value: customer.email,
      label: customer.email,
    };
  });

  const selectedCustomers =
    (data as any)?.users?.map((user: any) => {
      return {
        id: user.id,
        value: user.emailIdentifier,
        label: user.emailIdentifier,
      };
    }) ?? [];

  const handleCustomerValue = (selectedOption: any) => {
    // propertyForm?.setFieldTouched("countryId", true);
    // if (selectedOption?.value) {
    //   const selectedCountry = countries?.find(
    //     (country: any) => country?.value === selectedOption?.value
    //   );
    //   if (selectedCountry) {
    //     const currency = currencyList?.find(
    //       (currency) => currency?.value === selectedCountry?.currencyCode
    //     );
    //     if (currency) {
    //       memberForm?.setValues((prevData: any) => ({
    //         ...prevData,
    //         countryId: selectedCountry?.value,
    //         countryName: selectedCountry?.label,
    //         code: selectedCountry?.code,
    //         currencyCode: selectedCountry?.currencyCode,
    //         currencyName: currency?.label,
    //       }));
    //     }
    //   }
    // } else {
    propertyForm.setValues((prevData: any) => ({
      ...prevData,
      propertyUsers: selectedOption.map((item: any) => {
        return { userId: item.id };
      }),

      // countryId: null,
      // countryName: "",
      // code: "",
      // currencyCode: "",
      // currencyName: "",
    }));
    // }
  };

  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
        {textFields.map((field, index: number) => (
          <Grid item xs={12} key={field.id}>
            <InputTextfield
              form={propertyForm}
              fieldProps={field}
              key={index}
            />
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Property Settings" {...a11yProps(0)} />
            <Tab label="Report Settings" {...a11yProps(1)} />
            <Tab label="Billing Settings" {...a11yProps(2)} />
            <Tab label="User Settings" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={3}>
          <ZSAutocomplete
            multiSelect={true}
            label="Authorized Clients"
            options={customers}
            disableClearable={true}
            placeholder="Select client"
            refetchFunction={(searchString: string) => {
              setPayload((prevData: any) => {
                return { ...prevData, search: searchString };
              });
            }}
            setValue={handleCustomerValue}
            setBlur={() => {
              propertyForm.setFieldTouched("authorizedClients", true);
            }}
            defaultValue={selectedCustomers}
          />
          <br />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid xs={2}>
            <ToggleSwitch
              label="Enable-Analysis"
              status={propertyForm?.values?.enableAnalysis}
              handleChange={() => {
                propertyForm?.setFieldValue(
                  "enableAnalysis",
                  !propertyForm?.values?.enableAnalysis
                );
              }}
            />
          </Grid>
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
            {reportSettingsFields.map((field, index: number) => (
              <Grid item xs={12} key={field.id}>
                <InputTextfield
                  form={propertyForm}
                  fieldProps={field}
                  key={index}
                />
              </Grid>
            ))}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={0}>
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
            {propertySettingsFields.map((field, index: number) => (
              <Grid item xs={12} key={field.id}>
                <InputLabel htmlFor="label">{field.label}</InputLabel>
                <OutlinedInput
                  name={field.name}
                  label={field.label}
                  fullWidth
                  placeholder={field?.placeholder}
                  type="text"
                  id={field.id}
                  value={
                    propertyForm?.values?.propertySetting[
                      field?.name as keyof typeof propertyForm.values.propertySetting
                    ]
                  }
                  onChange={(e: any) => {
                    propertyForm?.setFieldValue(
                      `propertySetting.${field.name}`,
                      e.target.value
                    );
                  }}
                  // error={Boolean(
                  //   (propertyForm.touched as any).propertySetting[$field.name]
                  // )}
                  error={
                    // (propertyForm as any)?.propertySetting?.[
                    //   field?.name
                    // ] &&
                    (propertyForm.errors as any)?.propertySetting?.[field.name]
                  }
                />
                {(propertyForm.errors as any)?.propertySetting?.[
                  field.name
                ] && (
                  <ErrorMessage
                    message={
                      (propertyForm.errors as any)?.propertySetting?.[
                        field.name
                      ] ?? ""
                    }
                  />
                )}
              </Grid>
            ))}

            {/* <Grid item xs={12} className="phoneTextfield">
              <TelePhoneInputField
                fieldProps={{
                  id: "property-phone",
                  name: "phone",
                  label: "Phone Number",
                  placeholder: "Enter phone number",
                }}
                form={propertyForm}
                detectCountryCode={
                  id && (data as any)?.propertySetting.phone ? false : true
                }
                value={contact}
                handleChange={(phone: string, info: any) => {
                  setContact(phone);
                  setStatus(!status);
                  propertyForm.setValues((prevData: any) => {
                    return {
                      ...prevData,
                      phone,
                      phoneNumber: info.nationalNumber,
                    };
                  });
                }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <InputLabel className="countryImageFieldLabel">
                Logo: Google
              </InputLabel>
              <InputImageUpload
                onImagesUploaded={handleImageUpload}
                existingImages={[]}
                setFieldTouched={propertyForm?.setFieldTouched}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className="countryImageFieldLabel">
                Logo: TripAdvisor
              </InputLabel>
              <InputImageUpload
                onImagesUploaded={handleImageUpload}
                existingImages={[]}
                setFieldTouched={propertyForm?.setFieldTouched}
              />
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Grid container spacing={0}>
            <Grid xs={10}></Grid>

            <Grid xs={2}>
              <ToggleSwitch
                label="Enable Billing"
                status={propertyForm?.values?.enablePropertyBilling}
                handleChange={() => {
                  propertyForm?.setFieldValue(
                    "enablePropertyBilling",
                    !propertyForm?.values?.enablePropertyBilling
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
            {billingSettingsFields.map((field, index: number) => (
              <Grid item xs={12} key={field.id}>
                <InputTextfield
                  form={propertyForm}
                  fieldProps={field}
                  key={index}
                />
              </Grid>
            ))}
          </Grid>
          <br />
          <Typography>Invoices</Typography>
          <Divider />
          <br />
          {addLink.map((linkField: any, index: any) => {
            return (
              <>
                <>
                  <Grid
                    container
                    alignItems="stretch"
                    columnSpacing={4}
                    rowSpacing={2}
                  >
                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        id="property-linkText"
                        name="propertyLinkText"
                        label="Link Text"
                        value={linkField.text}
                        onChange={(event) => setLinkText(event, index)}
                        required={true}
                      />
                      <br />
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        id="property-linkUrl"
                        name="propertyLinkUrl"
                        label="Link URL"
                        value={linkField.link}
                        onChange={(event) => setLinkUrl(event, index)}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleRemoveLinkField(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
                <br />
              </>
            );
          })}
          <br />
          <Button
            variant="contained"
            onClick={() => {
              handleAddLink();
            }}
          >
            Add Link
          </Button>
        </CustomTabPanel>
      </Box>
      <Button
        // propertyForm.values
        disabled={
          propertyForm.values.title === "" ||
          propertyForm.values.status === "Pending Review" ||
          propertyForm.values.status === "Published"
        }
        variant="contained"
        onClick={() => {
          handleSaveDraft();
        }}
        style={{ float: "right" }}
        className="float-right"
      >
        Save Draft
      </Button>
    </FormContainer>
  );
};
export default Property;
