import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import loginLogo from "./loginLogo.svg";

const LogoWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "280px",
  height: "300px",
}));
function LogoStartup() {
  return (
    <Link to="/">
      <LogoWrapper>
        <img src={loginLogo} alt={loginLogo} />
      </LogoWrapper>
    </Link>
  );
}

export default LogoStartup;
