/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";
import { EntityContext } from "../../../../../contexts/EntityContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import Loader from "../../../../../shared/components/loader/linearProgress";
import TitleBar from "../../../../../shared/components/titleBar";
import NoPermissionContent from "../../../../../shared/components/noPermissionBanner";
import BlogDataTable from "../../../../../shared/components/blogDataTable";

const BlogsList: FC = () => {
  const navigate = useNavigate();
  const { setEntityName } = useContext(EntityContext);
  const { payload, setPayload } = useContext(SidebarContext);
  const [status, setStatus] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [clearFilter, setClearFilter] = useState(false);

  const navigateToDetails = () => {
    setEntityName("Blogs");
    navigate("/settings/blogs/create");
  };

  const {
    loading: blogLoading,
    data: blogData,
    fetchData: blogFetch,
    error: blogError,
  } = useQuery("/admin/settings/blog", {
    params: {
      ...payload,
    },
  });
  useEffect(() => {
    blogFetch();
  }, []);
  console.log("blogdata", blogData);

  const blogDisplayColumns = ["title", "order"];

  const blog = (blogData as any)?.items?.map((blog: any) => {
    return {
      ...blog,
      title: `${blog?.text}`,
      blogId: blog?.id,
    };
  });

  return (
    <>
      <Container className="propertiesTableContainer">
        <TitleBar
          title="Blogs"
          // buttonTitle="Create"
          headerTitle="Blogs"
          onClick={navigateToDetails}
        />
        {blogLoading ? (
          <Loader />
        ) : (blogError as any)?.statusCode === 403 ? (
          <NoPermissionContent />
        ) : (
          <>
            <Grid container spacing={2}></Grid>
            <BlogDataTable
              entityName="Blogs"
              data={blog}
              displayColumns={blogDisplayColumns}
              linkColum="name"
              linkUrl="/settings/blogs"
              pagination={{ take: payload.take, skip: payload.skip }}
              setPagination={setPayload}
              totalItems={(blogData as any)?.totalItems}
              // deleteApiUrl="/admin/properties"
              refetchFunction={blogFetch}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default BlogsList;
