/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Hidden,
  Popover,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import request from "../../../../request";
import LockOpenTwoToneIcon from "@mui/icons-material/LockOpenTwoTone";
import "./styles.css";
import {
  clearLocalStorageData,
  getLocalStorageData,
} from "../../../../shared/utils/localStorage";
import useMutation from "../../../../shared/components/hooks/useMutation";

const UserBoxButton = styled(Container)(
  ({ theme }) => `
        display: flex;
        align-items: center;
 `
);
const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(1.2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
  text-align: center;
  justify-content: center;
  padding: ${theme.spacing(0.5)};
  `
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
         font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
         display: block;
`
);

function HeaderUserbox() {
  const localStorageData = getLocalStorageData("userData");
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { modifyData } = useMutation();
  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async () => {
    const response = await modifyData(`/admin/logout`, "post");
    if (response) {
      clearLocalStorageData();
      window.location.href = "/login";
    }
  };
  const navigate = useNavigate();

  const name = localStorageData?.firstName;
  const avatarName = name?.charAt(0)?.toUpperCase();

  return (
    <>
      <UserBoxButton className="userBox" ref={ref}>
        <UserBoxText>
          <UserBoxLabel>
            <Link
              to={`/admin/${
                localStorage.userId ? localStorageData.userId : ""
              }`}
              style={{ textDecoration: "none" }}
            >
              <span className="adminName">{name}</span>
            </Link>
          </UserBoxLabel>
        </UserBoxText>
        &nbsp;
        <UserBoxText className="adminIcon" onClick={handleOpen}>
          <span className="avatar">{avatarName}</span>
        </UserBoxText>
        <Hidden smDown></Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox className="MenuUserBox" display="flex">
          <Avatar src={""} sx={{ width: 35, height: 35 }} />{" "}
          <UserBoxText>
            <UserBoxLabel variant="body1">
              {localStorageData?.firstName}
            </UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider />
        <Box className="Box">
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              handleLogout();
            }}
          >
            {" "}
            <LockOpenTwoToneIcon className="LockOpenTwoToneIcon" />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
