import React, { useState, ChangeEvent, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { ReactComponent as IconUpload } from "./icons/icon upload.svg";
import IconClose from "./icons/icon close web.svg";
import "./styles.css";
import CircularLoader from "../loader/circularProgress";
import { Typography } from "@mui/material";

const acceptedImageTypes = ["jpeg", "png", "jpg"];
interface InputImageUploadProps {
  onImagesUploaded: Function;
  existingImages: string[];
  setFieldTouched?: any;
  id?: string;
}

const InputImageUpload: React.FC<InputImageUploadProps> = ({
  id,
  onImagesUploaded,
  existingImages,
  setFieldTouched,
}) => {
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const handleImageUpload = (images: FileList | null) => {
    setIsUploading(true);
    if (!images) return;
    const imageArray: any[] = [],
      imageUrlArray: string[] = [];
    for (let i = 0; i < images.length; i++) {
      const url = URL.createObjectURL(images[i]);
      imageUrlArray.push(url);
      imageArray.push({ [url]: images[i] });
    }

    setTimeout(() => {
      setUploadedImages([...uploadedImages, ...imageUrlArray]);
      onImagesUploaded(imageArray);
      setIsUploading(false);
    }, 1000);
  };
  useEffect(() => {
    if (existingImages?.length > 0) {
      setUploadedImages(existingImages);
    }
  }, [existingImages]);

  const handleDelete = async (imageToDelete: string) => {
    setUploadedImages((prevImages) =>
      prevImages.filter((image) => {
        return image !== imageToDelete;
      })
    );
    onImagesUploaded([], imageToDelete);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        md={2}
        style={{
          maxWidth: "150px",
        }}
      >
        <Button component="label" className="uploadButton" variant="contained">
          {isUploading ? (
            <CircularLoader />
          ) : (
            <>
              <Grid container className="imageInputContainer">
                <div className="uploadIcon">
                  <IconUpload />
                </div>
                <Typography className="uploadImageText">
                  Upload Image
                </Typography>
              </Grid>

              <input
                id={id}
                className="uploadedImage"
                type="file"
                multiple
                accept={acceptedImageTypes.join(",")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleImageUpload(e.target.files);
                }}
                onBlur={() => {
                  setFieldTouched("image", true);
                }}
              />
            </>
          )}
        </Button>
      </Grid>
      <Grid item md={10}>
        <Grid className="imageContainer">
          {uploadedImages.map((image, imageIndex) => {
            return (
              <Grid key={imageIndex}>
                <Grid key={imageIndex} className="uploadedImageContainer">
                  <img
                    className="uploadedImageStyle"
                    src={image}
                    alt={`uploaded-${imageIndex}`}
                  />
                  <div
                    onClick={() => handleDelete(image)}
                    className="closeButton"
                  >
                    <img src={IconClose} alt="IconClose"></img>
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InputImageUpload;
