/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import "./styles.css";
import { EntityContext } from "../../../contexts/EntityContext";
import { LoadingButton } from "@mui/lab";
type TitleBarProps = {
  title: string;
  buttonTitle?: string;
  onClick?: Function;
  disabled?: boolean;
  // setEntity?: boolean;
  headerTitle?: string;
  children?: React.ReactNode;
  buttonLoading?: boolean;
};
const TitleBar: FC<TitleBarProps> = ({
  title,
  buttonTitle,
  onClick,
  disabled,
  // setEntity = true,
  headerTitle,
  children,
  buttonLoading = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setEntityName } = useContext(EntityContext);

  useEffect(() => {
    if (title) setEntityName({ entityName: title, entityTitle: headerTitle });
  }, [title]);

  return (
    <>
      <Grid container justifyContent="space-between" className="titleBar">
        <Grid item alignItems={"left"} className="title">
          <Typography className="titleBarTitle">{title}</Typography>
        </Grid>

        <Grid item alignItems={"right"} display={"flex"} gap={1}>
          {children && (
            <div className="TitleBarComponent">{children ?? ""}</div>
          )}
          {buttonTitle && onClick ? (
            <LoadingButton
              className="TitleBarButton"
              loading={buttonLoading}
              variant="contained"
              size="small"
              id={`${title}-${buttonTitle}`}
              disabled={disabled ?? false}
              onClick={() => onClick && onClick()}
            >
              {buttonTitle}
            </LoadingButton>
          ) : (
            !children && <div className="TitleBarButton"></div>
          )}
        </Grid>
      </Grid>
      <hr className="divider" />
    </>
  );
};

export default TitleBar;
