/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { FC, useContext, useEffect } from "react";
import ToggleSwitch from "../../../../shared/components/switch";
import InputTextfield from "../../../../shared/components/textField";
import useMutation from "../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../shared/validations/schema";
import useLazyQuery from "../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../shared/components/formContainer";
import { matchIsValidTel } from "mui-tel-input";
import TelePhoneInputField from "../../../../shared/components/telephoneInput";
// import currencyList from "../../../../shared/json/currency.json";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import useQuery from "../../../../shared/components/hooks/useQuery";
import { ZSAutocomplete } from "../../../../shared/components/autoComplete";

const Admin: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation("/admin");
  const { payload, setPayload } = useContext(SidebarContext);

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/administrators/${id}`,
    "/admin"
  );

  const { data: rolesData } = useQuery("/admin/roles", {
    params: {
      ...payload,
      take: 10,
      sortOrder: "ASC",
      enabled: true,
      role: "Admin",
    },
  });

  const selectedRole =
    data && (data as any)?.user?.roleId
      ? {
          value: (data as any)?.user?.roleId,
          label: (data as any)?.user?.role?.name,
        }
      : null;

  const roles = (rolesData as any)?.items?.map((role: any) => ({
    label: role?.name,
    value: role?.id,
  }));

  // const handleDOBChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let value = e.target.value.replace(/\D/g, "");
  //   let dayPart = value.slice(0, 2);
  //   let monthPart = value.slice(2, 4);

  //   if (dayPart === "00") dayPart = "01";
  //   if (monthPart === "00") monthPart = "01";

  //   if (parseInt(dayPart, 10) > 31) dayPart = "31";
  //   if (parseInt(monthPart, 10) > 12) monthPart = "12";

  //   if (monthPart === "02" && parseInt(dayPart, 10) > 29) dayPart = "29";
  //   else if (
  //     ["04", "06", "09", "11"].includes(monthPart) &&
  //     parseInt(dayPart, 10) > 30
  //   )
  //     dayPart = "30";
  //   value = dayPart + (value.length >= 2 ? "/" + monthPart : "");
  //   if (e.target.value.charAt(e.target.value.length - 1) === "/") {
  //     value = dayPart;
  //   }
  //   adminForm.setFieldValue("dob", value);
  // };

  const textFields = [
    {
      id: "admin-firstName",
      name: "firstName",
      label: "First Name",
      type: "text",
      placeholder: "Enter first name",
      required: true,
    },
    {
      id: "admin-lastName",
      name: "lastName",
      label: "Last Name",
      type: "text",
      placeholder: "Enter last name",
      required: true,
    },
    {
      id: "admin-email",
      name: "email",
      label: "Email Address",
      type: "text",
      placeholder: "Enter email address",
      required: true,
    },
    {
      id: "admin-userName",
      name: "userName",
      label: "User Name",
      type: "text",
      placeholder: "Enter User Name",
      required: true,
    },
    // {
    //   id: "admin-role",
    //   name: "roleId",
    //   label: "Role",
    //   type: "text",
    //   placeholder: "Select Role",
    //   required: true,
    // },
  ];
  // const selecteRole =
  //   {
  //     id: (data as any)?.user?.roleId,
  //     value: (data as any)?.user?.role?.name,
  //     label: (data as any)?.user?.role?.name,
  //   } || {};

  const adminForm = useFormik({
    initialValues: {
      firstName: (data as any)?.firstName ?? "",
      lastName: (data as any)?.lastName ?? "",
      email: (data as any)?.email ?? "",
      phoneNumber: (data as any)?.phone ?? null,
      phone: (data as any)?.phone ? `+${(data as any)?.phone}` : null,
      enabled: (data as any)?.enabled ?? true,
      enable2Fa: (data as any)?.user?.enable2Fa ?? false,
      userName: (data as any)?.user?.userName ?? "",
      dob: (data as any)?.dob ?? null,
      roleId: (data as any)?.user?.roleId ?? "",
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema([
      "firstName",
      "lastName",
      "email",
      "userName",
      "address",
      "companyName",
      "companyRegistrationNo",
      "dob",
      "roleId",
    ]),
    validate: (values: any) => {
      const errors: any = {};
      if (values?.phoneNumber && matchIsValidTel(values?.phone) === false) {
        errors.phone = "Invalid Phone Number";
      }
      return errors;
    },
    onSubmit: async (formData: any) => {
      if (!formData.dob) {
        formData.dob = null;
      }
      if (!formData.phoneNumber) {
        formData.phone = null;
      }

      if (id) {
        modifyData(
          `/admin/administrators/${id}`,
          "put",
          {
            ...formData,
          },
          adminForm
        );
      } else {
        modifyData(
          `/admin/administrators`,
          "post",
          {
            ...formData,
          },
          adminForm
        );
      }
    },
  });

  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  const titleBar = {
    title: id
      ? `${(data as any)?.firstName ?? ""} ${(data as any)?.lastName ?? ""}`
      : "Create New Admin",
    buttonTitle: id ? "Update" : "Create",
    headerTitle: "Admin",
    onClick: () => adminForm.handleSubmit(),
    dirty: adminForm.dirty,
    disabled: Object.keys(adminForm?.errors).length !== 0,
    buttonLoading: apiLoading,
  };

  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <>
        <Grid container spacing={0}>
          <Grid xs={8}></Grid>
          <Grid xs={2}>
            <ToggleSwitch
              status={adminForm?.values?.enable2Fa}
              label="Enable2Fa"
              handleChange={() => {
                adminForm?.setFieldValue(
                  "enable2Fa",
                  !adminForm?.values?.enable2Fa
                );
              }}
            />
          </Grid>
          <Grid xs={2}>
            <ToggleSwitch
              status={adminForm?.values?.enabled}
              label="Enable"
              handleChange={() => {
                adminForm?.setFieldValue(
                  "enabled",
                  !adminForm?.values?.enabled
                );
              }}
            />
          </Grid>
        </Grid>
        <br />
        <Grid container alignItems="stretch" columnSpacing={4} rowSpacing={2}>
          {textFields.map((field, index: number) => (
            <Grid item xs={4} key={field.id}>
              <InputTextfield form={adminForm} fieldProps={field} key={index} />
            </Grid>
          ))}
          <Grid item md={4} sm={6} xs={12}>
            <ZSAutocomplete
              options={roles}
              label="Role"
              placeholder="Select role"
              required
              setValue={(selectedOption: any) => {
                adminForm.setFieldTouched("roleId", true);
                if (selectedOption?.value) {
                  adminForm.setFieldValue("roleId", selectedOption?.value);
                } else {
                  adminForm.setFieldValue("roleId", "");
                }
                adminForm.setValues((prevData: any) => {
                  return {
                    ...prevData,
                    roleId: selectedOption?.value,
                  };
                });
              }}
              defaultValue={selectedRole}
              setBlur={() => {
                adminForm.setFieldTouched("roleId", true);
              }}
              errorMessage={
                ((adminForm.touched as any)?.roleId &&
                  (adminForm.errors as any)?.roleId) ??
                null
              }
              refetchFunction={(searchString: string) => {
                setPayload((prevData: any) => {
                  return { ...prevData, search: searchString };
                });
              }}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <InputLabel className="dobLabel">Date of Birth</InputLabel>
            <TextField
              id="dob"
              name="dob"
              type="text"
              required={false}
              placeholder="DD/MM"
              onChange={handleDOBChange}
              value={adminForm?.values?.dob || ""}
              fullWidth
              error={Boolean(
                (adminForm.touched as any)?.dob &&
                  (adminForm.errors as any)?.dob
              )}
              helperText={
                (adminForm.touched as any)?.dob &&
                (adminForm.errors as any)?.dob
              }
              onBlur={adminForm.handleBlur}
            />
          </Grid> */}
          <Grid item xs={4} className="phoneTextfield">
            <TelePhoneInputField
              fieldProps={{
                id: "admin-phone",
                name: "phone",
                label: "Phone Number",
                placeholder: "Enter phone number",
              }}
              form={adminForm}
              detectCountryCode={id && (data as any)?.phone ? false : true}
              handleChange={(phone: string, info: any) => {
                adminForm.setValues((prevData: any) => {
                  return {
                    ...prevData,
                    phone,
                    phoneNumber: info.nationalNumber,
                  };
                });
              }}
            />
          </Grid>
        </Grid>
      </>
      {/* {id && <MemberHistory />} */}
    </FormContainer>
  );
};
export default Admin;
