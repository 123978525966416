/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Divider, Grid, InputLabel, TextField } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import ToggleSwitch from "../../../../../shared/components/switch";
import InputTextfield from "../../../../../shared/components/textField";
import useMutation from "../../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../../shared/validations/schema";
import useLazyQuery from "../../../../../shared/components/hooks/useLazyQuery";
import { FormContainer } from "../../../../../shared/components/formContainer";
import { matchIsValidTel } from "mui-tel-input";
import TelePhoneInputField from "../../../../../shared/components/telephoneInput";
// import currencyList from "../../../../shared/json/currency.json";
import { SidebarContext } from "../../../../../contexts/SidebarContext";
import useQuery from "../../../../../shared/components/hooks/useQuery";
import Editor from "react-simple-wysiwyg";
import InputImageUpload from "../../../../../shared/components/imageUpload";

const BillingSettings: FC = () => {
  const { id } = useParams();
  const { loading: apiLoading, modifyData } = useMutation(
    "/settings/billingSettings"
  );
  const { payload, setPayload } = useContext(SidebarContext);
  const [billingData, setBillingData] = useState("");
  const { loading, data, fetchData } = useLazyQuery(
    `/admin/settings/billing`,
    "/billing"
  );
  useEffect(() => {
    fetchData();
  }, []);
  // {
  //   id: "admin-role",
  //   name: "roleId",
  //   label: "Role",
  //   type: "text",
  //   placeholder: "Select Role",
  //   required: true,
  // },
  // const selecteRole =
  //   {
  //     id: (data as any)?.user?.roleId,
  //     value: (data as any)?.user?.role?.name,
  //     label: (data as any)?.user?.role?.name,
  //   } || {};

  const billingForm = useFormik({
    initialValues: {
      billingContent: {
        header: (data as any)?.billingContent
          ? (data as any)?.billingContent.header
          : null,
        footer: (data as any)?.billingContent
          ? (data as any)?.billingContent.footer
          : null,
      },
    },
    enableReinitialize: true,
    // validationSchema: getValidationSchema(["billingData"]),
    validate: (values: any) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (formData: any) => {
      modifyData(
        `/admin/settings/billing`,
        "post",
        {
          ...formData,
        },
        billingForm
      );
    },
  });

  const titleBar = {
    title: "Update Billing Data",
    buttonTitle: "Update",
    headerTitle: "Billing",
    onClick: () => billingForm.handleSubmit(),
    dirty: billingForm.dirty,
    // disabled: Object.keys(billingForm?.errors).length !== 0,
    buttonLoading: apiLoading,
  };
  const handleImageUpload = (files: string[]) => {
    billingForm.setFieldValue("image", files);
  };

  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <>
        <h2>Billing Data</h2>
        <Divider />
        <h2>Header</h2>
        <Editor
          style={{ height: "250px" }}
          value={billingForm?.values?.billingContent?.header}
          onChange={(e: any) => {
            billingForm?.setFieldValue(`billingContent.header`, e.target.value);
          }}
        />
        <br />
        <h2>Footer</h2>
        <Editor
          style={{ height: "250px" }}
          value={billingForm?.values?.billingContent?.footer}
          onChange={(e: any) => {
            billingForm?.setFieldValue(`billingContent.footer`, e.target.value);
          }}
        />
        <br />
      </>
    </FormContainer>
  );
};
export default BillingSettings;
