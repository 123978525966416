import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Grid,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import "./styles.css";
const NoPermissionContent = () => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" className="noPermissionTableCell">
              <Grid>
                <LockIcon />
              </Grid>
              <Grid color="red" fontSize={30}>
                Access Denied!
              </Grid>
              <Grid>You don't have permission to access this page</Grid>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default NoPermissionContent;
