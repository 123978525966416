export const getLocalStorageData = (item: string) => {
  const localStorageData = JSON?.parse(localStorage?.getItem(item) || "{}");
  return Object.keys(localStorageData)?.length > 0 ? localStorageData : null;
};

export const setLocalStorageData = (item: string, payload: any) => {
  localStorage.setItem(item, JSON.stringify(payload));
};

export const clearLocalStorageData = () => {
  localStorage.clear();
};

export const removeLocalStorageData = (item: string) => {
  localStorage.removeItem(item);
};
