import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import "./styles.css";
import { FC, useEffect, useState } from "react";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PaginationComponent from "../pagination";
import NoDataFound from "../noDataFoundBanner";
import DeletePermissionDialog from "../deletePermissionModal";
import useMutation from "../hooks/useMutation";
import { Link, useNavigate } from "react-router-dom";
import ToggleSwitch from "../switch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getLocalStorageData } from "../../utils/localStorage";
import SendIcon from "@mui/icons-material/Send";
import request from "../../../request";
type PropertyDataTableProps = {
  data: Object[];
  entityName?: string;
  linkColum?: string;
  linkUrl?: string;
  pagination: any;
  setPagination: Function;
  displayColumns: string[];
  totalItems?: number;
  deleteApiUrl?: string;
  refetchFunction?: Function;
};

const PropertyDataTable: FC<PropertyDataTableProps> = ({
  data,
  entityName,
  linkColum,
  linkUrl,
  pagination,
  setPagination,
  displayColumns,
  totalItems,
  deleteApiUrl,
  refetchFunction = () => {},
}) => {
  const { modifyData } = useMutation(linkUrl);
  const [deletedRecord, setDeletedRecord] = useState<any>();
  const [checked, setChecked] = useState(false);
  const [statusId, setStatusId] = useState<any>();
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  const [mailLoader, setMailLoader] = useState<any>(null);
  const [statusMessage, setStatusMessage] = useState<any>();
  const handleDelete = async () => {
    await modifyData(`${deleteApiUrl}/${deletedRecord.id}`, "delete");
    setDeletedRecord(null);
    refetchFunction();
  };
  const handleSwitchChange = (e: any) => {
    setChecked(e.target.checked);
  };
  if (statusId) {
    modifyData(`/admin/properties/${statusId}/change-status`, "put", {
      status: statusMessage,
    });
    setStatusId("");
    refetchFunction();
  }
  const booleanStatus: any = {
    verified: {
      true: "Verified",
      false: "Not Verified",
    },
    status: {
      true: "Success",
      false: "Failed",
    },
    enabled: {
      true: "Enabled",
      false: "Disabled",
    },
  };
  // const calculateMaxHeight = () => {
  //   const percentageValue = 50;

  //   const maxHeight = (window.innerHeight * percentageValue) / 100;
  //   return maxHeight;
  // };
  // const token = JSON.parse(localStorage.getItem("Token:") || "{}");
  const token = getLocalStorageData("token");
  const handleClick = (dataItem: any) => {
    const urlRedirectId = dataItem.propertyId;
    // Append the token to the URL as a query parameter
    if (urlRedirectId) {
      const urlWithToken = `${
        process.env.REACT_APP_CLIENT_API_URL
      }/dashboard/${urlRedirectId}?token=${encodeURIComponent(token)}`;

      // Redirect to the new URL
      // window.location.href = urlWithToken;
      window.open(
        urlWithToken,
        "_blank" // <- This is what makes it open in a new window.
      );
    }
  };
  const sendMail = async (dataItem: any) => {
    // setMailLoader(true);
    const response = await request.post(
      `/admin/properties/${dataItem.propertyId}/send-activation-mail`
    );
    //   refetchFunction();
    // setMailLoader(false);
  };

  return (
    <>
      {data?.length > 0 ? (
        <>
          {deletedRecord ? (
            <DeletePermissionDialog
              open={deletedRecord ? true : false}
              handleClose={() => {
                setDeletedRecord(null);
              }}
              deletedData={{
                name: linkColum ? deletedRecord[linkColum] : "",
                content: entityName ?? "",
              }}
              handleDelete={handleDelete}
            />
          ) : (
            ""
          )}
          {/* <Scrollbars autoHeight autoHeightMax="55vh"> */}
          <div className="scrollBar">
            <Table
              className={linkUrl ? "dataTable editableDataTable" : "dataTable"}
            >
              <TableHead>
                <TableRow className="headerRow">
                  <TableCell align="center" className="columnName">
                    Sl No
                  </TableCell>
                  {displayColumns.map((title, columnIndex: number) => {
                    return (
                      <TableCell
                        align="center"
                        className="columnName"
                        key={columnIndex}
                      >
                        {title === "createdAt"
                          ? "Date & Time"
                          : title.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center" className="columnName">
                    Status Change
                  </TableCell>
                  {deleteApiUrl ? (
                    <TableCell align="center" className="columnName">
                      Actions
                    </TableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((dataItem: any, dataIndex: number) => (
                  <TableRow
                    key={dataIndex}
                    onClick={() => {
                      linkUrl && navigate(`${linkUrl}/${dataItem.id}`);
                    }}
                  >
                    <TableCell align="center" className="tableCell">
                      {pagination?.skip * pagination?.take + dataIndex + 1}
                    </TableCell>
                    {displayColumns.map((title, columnIndex: number) => {
                      return (
                        <TableCell
                          align="center"
                          className="tableCell"
                          key={columnIndex}
                        >
                          {linkColum === title ? (
                            <Link
                              className="link"
                              to={linkUrl + `/${dataItem.id}`}
                            >
                              {dataItem[title]}
                            </Link>
                          ) : typeof dataItem[title] == "boolean" ? (
                            <Chip
                              label={booleanStatus?.[title]?.[dataItem[title]]}
                              variant="outlined"
                              className={
                                dataItem[title] ? "chipEnabled" : "chipDisabled"
                              }
                            />
                          ) : title === "createdAt" ? (
                            new Date(dataItem[title]).toLocaleString()
                          ) : (
                            <span>
                              {dataItem[title]?.length > 35 ? (
                                <span>
                                  {dataItem[title].slice(0, 35)}
                                  <Tooltip title={dataItem[title]} arrow>
                                    <span className="readMore">
                                      {"....."}
                                      Read more
                                    </span>
                                  </Tooltip>
                                </span>
                              ) : (
                                dataItem[title]
                              )}
                            </span>
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell align="center" className="tableCell">
                      <Tooltip
                        title={
                          dataItem.status === "Pending Review"
                            ? "Switch status to Published"
                            : "Switch status to Pending Review"
                        }
                        arrow
                      >
                        <Switch
                          style={{ color: "#59C2B0" }}
                          id={`${dataItem.id}`}
                          checked={dataItem.status != "Pending Review"}
                          disabled={dataItem.status === "Draft"}
                          onChange={(e) => {
                            setStatusId(dataItem.propertyId);
                            setStatus(!status);
                            handleSwitchChange(e);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (dataItem.status === "Published") {
                              setStatusMessage("Pending Review");
                            } else {
                              setStatusMessage("Published");
                            }
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" className="tableCell">
                      {/* <Box display="flex" justifyContent="space-between"> */}
                      <Tooltip title={`Delete ${entityName}`} arrow>
                        <IconButton
                          id={`delete-${entityName}-${dataItem.id}`}
                          className="deleteIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeletedRecord(dataItem);
                          }}
                        >
                          <DeleteTwoToneIcon
                            style={{ color: "#59C2B0" }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="View Property" arrow>
                        <IconButton
                          style={{ color: "#59C2B0" }}
                          id={`delete-${entityName}-${dataItem.id}`}
                          className="viewIcon"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClick(dataItem);
                          }}
                        >
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="Send mail" arrow>
                          <IconButton
                            style={{ color: "#59C2B0" }}
                            id={`delete-${entityName}-${dataItem.id}`}
                            size="small"
                            className="viewIcon"
                            onClick={(e) => {
                              e.stopPropagation();
                              sendMail(dataItem);
                            }}
                          >
                            <SendIcon fontSize="small" />
                          </IconButton>
                        </Tooltip> */}
                      {/* </Box> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* </Scrollbars> */}
          </div>
        </>
      ) : (
        <NoDataFound />
      )}
      <Grid className="paginationGrid">
        <PaginationComponent
          totalItems={totalItems}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Grid>
    </>
  );
};

export default PropertyDataTable;
