import { KeyboardArrowDown } from "@mui/icons-material";
import "./styles.css";
import { Pagination, MenuItem, Select, Typography, Box } from "@mui/material";

interface CustomTablePaginationPropTypes {
  pagination: any;
  setPagination: Function;
  totalItems: any;
}

const PaginationComponent: React.FC<CustomTablePaginationPropTypes> = ({
  pagination,
  setPagination,
  totalItems,
}) => {
  const rowsPerPageOptions = [5, 10, 25, 50, 100];

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const rowsPerPage = event.target.value as number;
    setPagination((previousValues: any) => {
      return {
        ...previousValues,
        skip: 0,
        take: rowsPerPage,
      };
    });
  };

  return (
    <Box className="paginationWrapper">
      <Typography className="rowsPerPageText">Rows per page : </Typography>

      <Select
        className="paginationSelect"
        value={pagination.take || rowsPerPageOptions[0]}
        onChange={(event: any) => handleChangeRowsPerPage(event)}
        variant="outlined"
        IconComponent={KeyboardArrowDown}
        MenuProps={{
          PaperProps: {
            className: "customMenuPaper",
          },
        }}
      >
        {rowsPerPageOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <Pagination
        count={totalItems && Math.ceil(totalItems / pagination.take)}
        page={pagination.skip + 1}
        variant="outlined"
        shape="rounded"
        onChange={(_, newPage) => {
          setPagination((previousValues: any) => {
            return {
              ...previousValues,
              skip: newPage - 1,
            };
          });
        }}
      />
    </Box>
  );
};

export default PaginationComponent;
