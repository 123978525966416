import { Box, CircularProgress } from "@mui/material";
import "./styles.css";
import { FC } from "react";
const CircularLoader: FC = () => {
  return (
    <Box className="uploadLoader">
      <CircularProgress size={20} />
    </Box>
  );
};

export default CircularLoader;
