import { FC, createContext, useState } from "react";
import SnackbarAlert from "../shared/components/snackbarAlert";

export const SnackBarAlertContext = createContext({
  set: (newValue: any) => {},
});

export const SnackBarAlertProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [showSnackBarAlert, setShowSnackBarAlert] = useState<any>({
    status: false,
    type: "success",
    message: "",
  });
  const handleSnackBarAlert = (newValue: any) => {
    setShowSnackBarAlert(newValue);
  };

  const handleSnackbarClose = () => {
    setShowSnackBarAlert({ status: false, type: "", message: "" });
  };

  const contextValue = {
    set: handleSnackBarAlert,
  };

  return (
    <SnackBarAlertContext.Provider value={contextValue}>
      <SnackbarAlert
        showSnackBarAlert={showSnackBarAlert}
        handleSnackbarClose={handleSnackbarClose}
      />
      {children}
    </SnackBarAlertContext.Provider>
  );
};
