/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useContext, useEffect } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import PropTypes from "prop-types";
import { Button, Badge, Collapse, ListItem } from "@mui/material";

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: any;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  openMenu: string[];
  setOpenMenu: Function;
  menus: any;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  openMenu,
  setOpenMenu,
  menus,
  ...rest
}) => {
  const { toggleSidebar } = useContext(SidebarContext);

  useEffect(() => {
    if (active && menus?.children) {
      setOpenMenu((prev: any) => {
        return [...prev, menus];
      });
    }
  }, []);

  const toggleMenu = (menu: any): void => {
    setOpenMenu((prev: any) => {
      if (prev?.some((data: any) => data.name === menu.name)) {
        return prev?.filter((data: any) => data.name !== menu.name);
      } else {
        const foundItem = prev?.find((item: any) =>
          item?.children?.some((data: any) => data?.name === menu?.name)
        );
        if (foundItem) {
          return [foundItem, menu];
        } else {
          return [menu];
        }
      }
    });
  };

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({
            "Mui-active": openMenu.some((data: any) => data.name === name),
          })}
          startIcon={Icon && <Icon />}
          onClick={() => {
            toggleMenu(menus);
          }}
        >
          {name}
        </Button>
        <Collapse in={openMenu.some((data: any) => data.name === name)}>
          {children}
        </Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest}>
      <Button
        className={clsx({ "Mui-active": active })}
        component={RouterLink}
        onClick={() => {
          toggleSidebar();

          setOpenMenu((prev: any) => {
            const foundItem = prev?.find((item: any) =>
              item?.children?.some((data: any) => data?.name === menus?.name)
            );
            if (foundItem) {
              const parentItem = prev?.find((item: any) =>
                item?.children?.some(
                  (data: any) => data?.name === foundItem?.name
                )
              );
              if (parentItem) {
                return [parentItem, foundItem];
              } else {
                return [foundItem];
              }
            } else {
              return [];
            }
          });
        }}
        to={link}
        startIcon={Icon && <Icon />}
      >
        {name}
        {badge && <Badge badgeContent={badge} />}
      </Button>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.any,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
