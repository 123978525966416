/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { InputLabel } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { ErrorMessage } from "../errorMessage";
import { FC, useEffect, useState } from "react";
// import getIPInformation from "../../utils/ipInformation";

type FieldProps = {
  id: string;
  name: string;
  label?: string;
  placeholder: string;
  required?: boolean;
};

type TextfieldProps = {
  fieldProps: FieldProps;
  form: any;
  handleChange: Function;
  detectCountryCode?: boolean;
  value?: string;
};

const TelePhoneInputField: FC<TextfieldProps> = ({
  form,
  handleChange,
  fieldProps,
  value,
  detectCountryCode = true,
}) => {
  const [defaultCountryCode, setDefaultCountryCode] = useState<any>("IN");

  // const getDefaultCountry = async () => {
  //   // try {
  //   const data = await getIPInformation();
  //   setDefaultCountryCode(data?.country_code);
  //   // } catch (error) {
  //   // console.log(error);
  //   // }
  // };

  useEffect(() => {
    // if (detectCountryCode) getDefaultCountry();
  }, []);

  return (
    <>
      {fieldProps?.label && (
        <InputLabel className="inputLabel">
          {fieldProps.label}
          {fieldProps?.required ? " * " : ""}
        </InputLabel>
      )}
      <MuiTelInput
        className="inputField"
        id={fieldProps.id}
        name={fieldProps.name}
        placeholder={fieldProps.placeholder}
        defaultCountry={defaultCountryCode}
        value={value ?? form?.values?.phone}
        error={form?.touched?.phone && Boolean(form?.errors?.phone)}
        onChange={(phone, info) => {
          form?.setFieldTouched(fieldProps.name, true);
          handleChange(phone, info);
        }}
      />
      <ErrorMessage
        message={(form?.touched as any)?.phone && (form?.errors as any)?.phone}
      />
    </>
  );
};

export default TelePhoneInputField;
