import Role from "../role/components/role";
import RoleList from "../role/components/roleList";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import PaymentsIcon from "@mui/icons-material/Payments";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AnalysisSettings from "../analysisSettings/components/settings";
import BillingSettings from "../billingSettings/components/settings";
import BlogSettings from "../blogSettings/components";
import BlogsList from "../blogSettings/components/blogList";

const roleRoute = {
  icon: SettingsApplicationsIcon,
  name: "settings",
  before: "Role",
  path: "settings",
  link: "/settings",
  index: 8,
  children: [
    {
      path: "role",
      element: <RoleList />,
      name: "Role",
      link: "/settings/role",
      icon: AdminPanelSettingsIcon,
      index: 2,
    },
    {
      path: "role/create",
      element: <Role />,
    },
    {
      path: "role/:id",
      element: <Role />,
    },
    {
      path: "analysisSettings",
      element: <AnalysisSettings />,
      name: "Analysis Settings",
      link: "/settings/analysisSettings",
      icon: AnalyticsIcon,
      index: 3,
    },
    {
      path: "billingSettings",
      element: <BillingSettings />,
      name: "Billing Settings",
      link: "/settings/billingSettings",
      icon: PaymentsIcon,
      index: 4,
    },
    {
      path: "blogs",
      element: <BlogsList />,
      name: "Blogs",
      index: 5,
      link: "/settings/blogs",
      icon: RssFeedIcon,
    },
    {
      path: "/settings/blogs/update",
      element: <BlogSettings />,
    },
    {
      path: "blogs/:id",
      element: <BlogSettings />,
    },
    // {
    //   path: "billingSettings/create",
    //   element: <BillingSettings />,
    // },
    // {
    //   path: "billingSettings/:id",
    //   element: <BillingSettings />,
    // },
  ],
};
export default roleRoute;
