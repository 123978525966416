import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
// import metaDeskLogo from "./metaDeskLogo.svg";
import metadeskLogo from "./metadeskLogo.png";
const LogoWrapper = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  width: "220px",
  height: "29.871px",
}));
function Logo() {
  return (
    <Link to="/">
      <LogoWrapper>
        <img
          src={metadeskLogo}
          alt={metadeskLogo}
          width="110px"
          height="50px"
        />
      </LogoWrapper>
    </Link>
  );
}

export default Logo;
