import "./App.css";
import "../src/assets/css/style.css";
import ThemeProvider from "./theme/ThemeProvider";
import { CssBaseline } from "@mui/material";
import router from "./router";
import { useRoutes } from "react-router-dom";
import { EntityProvider } from "./contexts/EntityContext";
import { SnackBarAlertProvider } from "./contexts/SnackbarContext";

function App() {
  const content = useRoutes(router());

  return (
    <ThemeProvider>
      <SnackBarAlertProvider>
        <EntityProvider>
          <CssBaseline />
          {content}
        </EntityProvider>
      </SnackBarAlertProvider>
    </ThemeProvider>
  );
}

export default App;
