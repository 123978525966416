import { Navigate, RouteObject } from "react-router-dom";
import SidebarLayout from "./layouts/SidebarLayout";
import BaseLayout from "./layouts/BaseLayout";
import { useContext } from "react";
import { SidebarContext } from "./contexts/SidebarContext";
import Home from "./pages/dashboard/components/home/home";
// import EmailVerification from "./pages/emailVerification/components";
import ResetPasswordForm from "./pages/password/resetPassword/components";
import ForgotPasswordForm from "./pages/password/forgotPassword/components";
import Login from "./pages/authentication";

let appRoutes: RouteObject[];

const Router = () => {
  const { routes } = useContext(SidebarContext);

  appRoutes = [
    {
      path: "",
      element: <BaseLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },

        {
          path: "/login",
          element: <Login />,
        },

        // {
        //   path: "/verify-email/:id",
        //   element: <EmailVerification />,
        // },
        {
          path: "/forgot-password",
          element: <ForgotPasswordForm />,
        },
        {
          path: "/reset-password/:id",
          element: <ResetPasswordForm />,
        },
      ],
    },
    {
      element: <SidebarLayout />,
      children: routes?.concat([
        {
          path: "*",
          element: <Navigate to="" replace />,
        },
      ]),
    },
  ];

  return appRoutes;
};

export default Router;
