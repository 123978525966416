import { ErrorCodes, ErrorTypes } from "../enum";

type Dict<T = any> = { [key: string]: T };

export interface ErrorResponse {
  statusCode: Number;
  type: ErrorTypes;
  data: Dict;
  message: string;
}

export const formatAxiosError = (error: any): ErrorResponse => {
  const response: ErrorResponse = {
    statusCode: error?.statusCode || ErrorCodes.InternalError,
    data: error?.errors || {},
    message:
      error?.statusCode === ErrorCodes.InternalError || !error
        ? "Something went wrong"
        : error?.message,
    type: error?.isValidationError
      ? error?.errors?.statusCode
        ? ErrorTypes.Unhandled
        : ErrorTypes.Validation
      : ErrorTypes.Unhandled,
  };
  return response;
};

export const handleError = (
  formik: any,
  error: ErrorResponse,
  snackBarAlertContext?: any
) => {
  if (error.type === ErrorTypes.Unhandled) {
    snackBarAlertContext.set({
      status: true,
      type: "error",
      message: error.message,
    });
  } else if (error.type === ErrorTypes.Validation) {
    const errorObj: any = {};
    Object.keys(error?.data).forEach((key) => {
      errorObj[key] = error.data[key];
    });
    formik.setErrors(errorObj);
    return null;
  }
};
