/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import TitleBar from "../../../../shared/components/titleBar";
import DataTable from "../../../../shared/components/dataTable";
import { EntityContext } from "../../../../contexts/EntityContext";
import useQuery from "../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import Loader from "../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../shared/components/noPermissionBanner";

const CustomerList: FC = () => {
  const navigate = useNavigate();
  const { setEntityName } = useContext(EntityContext);
  const { payload, setPayload } = useContext(SidebarContext);

  const navigateToDetails = () => {
    setEntityName("Customer");
    navigate("/customer/create");
  };

  const { loading, data, fetchData, error } = useQuery("/admin/customers", {
    params: {
      ...payload,
    },
  });

  const adminDisplayColumns = [
    "name",
    "userName",
    "email",
    "phone",
    "verified",
    "enabled",
  ];

  const administrators = (data as any)?.items?.map((administrator: any) => {
    return {
      ...administrator,
      name: `${administrator?.firstName} ${administrator?.lastName}`,
      verified: administrator?.user?.verified,
      phone: administrator?.phone ? `+${administrator?.phone}` : null,
    };
  });

  return (
    <>
      <Container className="adminTableContainer">
        <TitleBar
          title="Customer"
          buttonTitle="Create"
          headerTitle="Customer"
          onClick={navigateToDetails}
        />
        {loading ? (
          <Loader />
        ) : (error as any)?.statusCode === 403 ? (
          <NoPermissionContent />
        ) : (
          <DataTable
            entityName="Customer"
            data={administrators}
            displayColumns={adminDisplayColumns}
            linkColum="name"
            linkUrl="/customer"
            pagination={{ take: payload.take, skip: payload.skip }}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            deleteApiUrl="/admin/customers"
            refetchFunction={fetchData}
          />
        )}
      </Container>
    </>
  );
};

export default CustomerList;
