/* eslint-disable array-callback-return */
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import "./styles.css";
import SeparatorImage from "./icons/separator.svg";
import { SidebarContext } from "../../../contexts/SidebarContext";
import { FC, useContext } from "react";
import { EntityContext } from "../../../contexts/EntityContext";
// type ZsBreadcrumbsProps = { isLastItem: any };
const ZsBreadcrumbs: FC = () => {
  const { routes } = useContext(SidebarContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { entity } = useContext(EntityContext);

  const pathnames = location.pathname.split("/").filter((x) => x);
  // discard parent menu
  const discardedItems = routes?.map((route: any) => {
    if (route?.children) return route?.name?.toUpperCase();
  });
  discardedItems?.push("DASHBOARD");

  const Separator = () => {
    return (
      <img src={SeparatorImage} alt="Separator" className="separatorArrow" />
    );
  };

  return (
    <Breadcrumbs separator={Separator()} className="breadcrumbs">
      {pathnames.length > 0 ? (
        <Link
          className="item"
          color="inherit"
          onClick={() => navigate("/dashboard")}
        >
          <Typography variant="h3">Dashboard</Typography>
        </Link>
      ) : (
        <Typography variant="h3">Dashboard</Typography>
      )}

      {pathnames.map((name, index) => {
        if (!discardedItems?.includes(name?.toUpperCase())) {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;

          const isLast = index === pathnames.length - 1;
          const isNumeric = /^\d+$/.test(name);
          return isLast ? (
            <Typography variant="h3" className="lastItem" key={name}>
              {isNumeric
                ? entity?.entityName
                : name === "create"
                ? entity?.entityName
                : name.replace(/-/g, " ")}
            </Typography>
          ) : (
            <Link
              variant="h3"
              color={"inherit"}
              className="item"
              onClick={() => navigate(routeTo)}
              key={name}
            >
              {isNumeric ? entity.entityName : name.replace(/-/g, " ")}
            </Link>
          );
        } else {
          return null;
        }
      })}
    </Breadcrumbs>
  );
};

export default ZsBreadcrumbs;
