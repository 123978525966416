/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { useContext, useEffect } from "react";
import { FormContainer } from "../../../../shared/components/formContainer";
import DashboardImage from "../../images/dashboard1.png";
import { EntityContext } from "../../../../contexts/EntityContext";
const Dashboard = () => {
  const { setEntityName } = useContext(EntityContext);
  useEffect(() => {
    setEntityName({ entityName: "Dashboard", entityTitle: "Dashboard" });
  }, []);
  return (
    <></>
    // <FormContainer>
    //   {/* <img className="graphDashboard" src={DashboardImage} alt="dashboard" /> */}
    // </FormContainer>
  );
};

export default Dashboard;
