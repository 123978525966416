/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { Grid, IconButton, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { SnackBarAlertContext } from "../../../../contexts/SnackbarContext";
import { useContext, useState } from "react";
import { useFormik } from "formik";
import { getValidationSchema } from "../../../../shared/validations/schema";
import { setLocalStorageData } from "../../../../shared/utils/localStorage";
import InputTextfield from "../../../../shared/components/textField";
import LogoStartup from "../../../../shared/loginLogo";
import { useNavigate } from "react-router-dom";
const Login = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const snackBarAlertContext = useContext(SnackBarAlertContext);
  const textFields = [
    {
      id: "login-username",
      name: "username",
      label: "Username",
      placeholder: "Enter your email address / phone number",
      type: "text",
      required: true,
    },
    {
      id: "login-password",
      name: "password",
      label: "Password",
      placeholder: "Enter your password",
      type: "password",
      required: true,
    },
  ];

  const loginForm = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: getValidationSchema(["username", "password"]),
    onSubmit: async (loginData: any) => {
      // const loginResponse: any = await modifyData(
      //   `/admin/login`,
      //   "post",
      //   loginData
      // );
      // console.log(loginResponse);
      setLoading(true);
      // const response: any = await fetch(
      //   `${process.env.REACT_APP_API_URL}/admin/login`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify(loginData),
      //   }
      // );
      // if (response?.ok) {
      //   setLoading(false);
      //   setLocalStorageData("token", response.headers.get("token"));
      //   console.log("response.", response?.body.json());
      //   setLocalStorageData("userData", response?.json());
      //   const blob = await response.blob();
      //   const url = URL.createObjectURL(blob);
      //   props.setQrCodeSrc(url);
      // } else {
      //   setLoading(false);
      //   const error = await response.json();
      //   snackBarAlertContext.set({
      //     status: true,
      //     type: "error",
      //     message: error?.message,
      //   });
      // }
      fetch(`${process.env.REACT_APP_API_URL}/admin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      })
        .then(async (response) => {
          const responseType = response.headers.get("Content-Type");
          setLocalStorageData("token", response.headers.get("token"));
          if (responseType === "image/png") {
            return response.blob().then((blob) => {
              const url = URL.createObjectURL(blob);
              props.setQrCodeSrc(url);
            });
          } else {
            const data = await response.json();
            setLoading(false);
            if (data.message === "Login successful") {
              setLocalStorageData("userData", data?.data);
              navigate("/dashboard");
              // navigate("/property");
            } else if (data.errors) {
              snackBarAlertContext.set({
                status: true,
                type: "error",
                message: data?.message,
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          // const error = await response.json();
          snackBarAlertContext.set({
            status: true,
            type: "error",
            message: error?.message,
          });
        });
      // if (response?.ok) {
      //   setLoading(false);
      //   setLocalStorageData("token", response.headers.get("token"));
      //   console.log("response.", response?.body.json());
      //   setLocalStorageData("userData", response?.json());
      //   const blob = await response.blob();
      //   const url = URL.createObjectURL(blob);
      //   props.setQrCodeSrc(url);
      // } else {
      //   setLoading(false);
      //   const error = await response.json();
      //   snackBarAlertContext.set({
      //     status: true,
      //     type: "error",
      //     message: error?.message,
      //   });
      // }
    },
  });
  return (
    <Grid container>
      <Grid item md={6} className="loginGridContainer">
        {/* <img src={LogoStartup} alt="LogoStartup" /> */}
        <LogoStartup />
      </Grid>
      <Grid item md={6} className="loginGridContainer">
        <form
          onSubmit={loginForm.handleSubmit}
          autoComplete="off"
          className="loginForm"
        >
          {textFields.map((field: any, index: number) => {
            return (
              <InputTextfield
                className="mediumInputBox"
                form={loginForm}
                fieldProps={field}
                key={index}
              />
            );
          })}
          <Grid container>
            <Grid item md={12}>
              <LoadingButton
                className="signInButton"
                loading={loading}
                variant="contained"
                type="submit"
                fullWidth
                size="small"
              >
                Login
              </LoadingButton>
            </Grid>
            <Grid item md={12}>
              <Link href="/forgot-password">Forgot password?</Link>
            </Grid>
            <Grid item md={12} className="socialMediaIconContainer">
              <IconButton
                size="large"
                className="googleIcon"
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`)
                }
              ></IconButton>
              <IconButton
                size="large"
                className="facebookIcon"
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_API_URL}/auth/facebook`)
                }
              ></IconButton>
              <IconButton
                size="large"
                className="linkedinIcon"
                onClick={() =>
                  (window.location.href = `${process.env.REACT_APP_API_URL}/auth/linkedin`)
                }
              ></IconButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
