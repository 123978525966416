import "./styles.css";
import { Box, Grid, Container, Divider } from "@mui/material";
// import Logo from "../../../shared/components/logo";
import { useState } from "react";
import OtpVerification from "./components/otpVerification";
import Login from "./components/login";
import Logo from "../../shared/logo";
const App = () => {
  const [qrCodeSrc, setQrCodeSrc] = useState<string | null>(null);

  return (
    <Container className="loginContainer">
      <Grid>
        <Box className="LoginPageLogo">
          <Logo />
          <br />
          <Divider />
        </Box>
        <Divider />
        <Box>
          {qrCodeSrc ? (
            <OtpVerification qrCodeSrc={qrCodeSrc} />
          ) : (
            <Login setQrCodeSrc={setQrCodeSrc} />
          )}
        </Box>
      </Grid>
    </Container>
  );
};

export default App;
