import { FC, useState, createContext, useEffect } from "react";
type EntityContext = {
  setEntityName: Function;
  entity: { entityName: string; entityTitle: string };
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityContext = createContext<EntityContext>({} as EntityContext);

export const EntityProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [entity, setEntityName] = useState({ entityName: "", entityTitle: "" });
  useEffect(() => {
    if (entity.entityTitle) document.title = entity.entityTitle;
  }, [entity.entityTitle]);

  return (
    <EntityContext.Provider
      value={{
        setEntityName,
        entity,
      }}
    >
      {children}
    </EntityContext.Provider>
  );
};
