import Admin from "../components/admin";
import AdminList from "../components/adminList";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
const adminRoute = [
  {
    path: "admin",
    element: <AdminList />,
    name: "Administrator",
    index: 2,
    link: "admin",
    icon: PeopleAltIcon,
  },
  {
    path: "admin/create",
    element: <Admin />,
  },
  {
    path: "admin/:id",
    element: <Admin />,
  },
];
export default adminRoute;
