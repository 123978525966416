import "./styles.css";
import { Box, Grid, Container, Divider } from "@mui/material";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  removeLocalStorageData,
  setLocalStorageData,
} from "../../../../shared/utils/localStorage";
import { LoadingButton } from "@mui/lab";
// import LoginImage from "./images/login.svg";
import InputTextfield from "../../../../shared/components/textField";
import { getValidationSchema } from "../../../../shared/validations/schema";
// import Logo from "../../../../shared/components/logo";
import useMutation from "../../../../shared/components/hooks/useMutation";
import Logo from "../../../../shared/logo";
import LoginLogo from "../../../../shared/loginLogo";
const App = () => {
  const { id } = useParams();
  setLocalStorageData("token", id);
  const { loading, modifyData } = useMutation("/login");

  const textFields = [
    {
      id: "login-new-password",
      name: "newPassword",
      label: "New Password",
      placeholder: "Enter new password",
      type: "password",
    },
    {
      id: "login-confirm-password",
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "Confirm new password",
      type: "password",
    },
  ];

  const passwordResetForm = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: getValidationSchema(["newPassword", "confirmPassword"]),
    onSubmit: async (formData: any) => {
      const response = await modifyData(`/reset-password`, "post", {
        password: formData.newPassword,
      });
      if (response) removeLocalStorageData("token");
    },
  });

  return (
    <Container className="resetPasswordContainer">
      <Grid>
        <Box className="resetPasswordLogo">
          {/* <Logo /> */}
          <Logo />
          <br />
          <Divider />
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={6} className="resetPasswordGridContainer">
              {/* <img src={LoginImage} alt="resetPasswordImage" /> */}
              <LoginLogo />
            </Grid>
            <Grid item xs={6} className="resetPasswordGridContainer">
              <form
                onSubmit={passwordResetForm.handleSubmit}
                autoComplete="off"
                className="passwordResetForm"
              >
                {textFields.map((field: any, index: number) => {
                  return (
                    <InputTextfield
                      key={index}
                      form={passwordResetForm}
                      className="mediumInputBox"
                      fieldProps={field}
                      information="Password must contain at least 8 character, including upper case, lower case, numeric value and special character"
                    />
                  );
                })}
                <Grid container>
                  <Grid item md={12}>
                    <LoadingButton
                      disabled={
                        passwordResetForm.values.newPassword === "" ||
                        passwordResetForm.values.confirmPassword === ""
                      }
                      className="signInButton"
                      loading={loading}
                      variant="contained"
                      type="submit"
                      fullWidth
                      size="small"
                    >
                      Reset Password
                    </LoadingButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default App;
