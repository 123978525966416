export enum ErrorCodes {
  InternalError = 500,
  success = 200,
  UnAuthorized = 401,
}

export enum ErrorTypes {
  Unhandled = "unhandled",
  Validation = "validation",
}
// export enum Features {
//   Administrator = "Administrator",
//   Customer = "Customer",
//   Property = "Property",
//   // Blog = "Blog",
//   Billing = "Billing",
//   Role = "Role",
//   // Settings = "Settings",
//   // ContentManagement = "Content Management",
// }

export const Features = {
  Administrator: {
    specialPermissions: ["Read", "Create", "Update", "Delete", "Send-Mail"],
    // Write: ["Customer"],
  },
  Customer: {
    specialPermissions: ["Read", "Create", "Update", "Delete", "Send-Mail"],
    // Write: ["Customer"],
  },
  Property: {
    specialPermissions: ["Read", "Create", "Update", "Delete"],
    Write: ["Customer"],
  },
  // Blog = "Blog",
  Billing: {
    specialPermissions: ["Read", "Update"],
  },
  Analytics: {
    specialPermissions: ["Read", "Update"],
  },
  Role: {},
  // Settings = "Settings",
  // ContentManagement = "Content Management",
};

export const FeaturesNew = {
  Member: { Write: ["Language", "Country"] },
  Service: { Write: ["Language"] },
  Subscription: { Write: ["Language", "Service"] },
  "Mail Upload": { Write: ["Member"] },
  Address: { Write: ["Language", "City"] },
  Translator: {},
  "Email Log": {
    specialPermissions: ["Read"],
  },
  Owner: { Write: ["Role"] },
  Staff: { Write: ["Role"] },
  Role: {},
  Language: {},
  Currency: {},
  Country: { Write: ["Language"] },
  City: { Write: ["Language", "Country"] },
  "Tax Category": {},
  "Tax Rate": { Write: ["Country", "TaxCategory"] },
  "Payment Method": {},
  "Login Report": {
    specialPermissions: ["Read"],
    Read: ["Owner"],
  },
  "Payment Report": {
    specialPermissions: ["Read"],
    Read: ["Member"],
  },
  "Invoice Report": {
    specialPermissions: ["Read"],
    Read: ["Member"],
  },
};
