import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../shared/components/loader/linearProgress";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/admin/customers");
        if (response.ok) {
          navigate("/dashboard");
        } else {
          navigate("/login");
        }
      } catch (error) {
        navigate("/login");
      }
    };

    fetchData();
  }, [navigate]);

  return <Loader />;
};

export default Home;
