import { Box, styled } from "@mui/material";

interface HomePageWrapperProps {
  children?: React.ReactNode;
}

const HomePageWrapperBox = styled(Box)(() => ({
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  overflow: "hidden",
  backgroundPosition: "center left",
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const HomePageWrapper: React.FC<HomePageWrapperProps> = ({
  children,
  ...rest
}) => {
  return <HomePageWrapperBox {...rest}>{children}</HomePageWrapperBox>;
};
export default HomePageWrapper;
