import "./styles.css";
import {
  Chip,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { FC, useState } from "react";
import { ErrorMessage } from "../errorMessage";
import { Visibility, VisibilityOff } from "@mui/icons-material";

type FieldProps = {
  id: string;
  name: string;
  label?: string;
  type: string;
  placeholder: string;
  prefix?: string;
  postfix?: string;
  required?: boolean;
  disabled?: string;
  value?: any;
};
type TextfieldProps = {
  fieldProps: FieldProps;
  form: any;
  value?: string;
  onChange?: Function;
  error?: string | boolean;
  information?: string;
  multiline?: boolean;
  disabled?: boolean;
  minRows?: number;
  className?: string;
  direction?: "rtl" | "ltr";
  children?: React.ReactNode;
};
const InputTextfield: FC<TextfieldProps> = ({
  fieldProps,
  form,
  information,
  multiline,
  minRows,
  className,
  value,
  error,
  onChange,
  direction = "ltr",
  children,
  disabled = false,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      fieldProps.type === "number" &&
      ["e", "E", "-", "+"].includes(event.key)
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className="inputBoxContainer">
      {fieldProps?.label && (
        <InputLabel htmlFor={fieldProps?.id}>
          {fieldProps?.label}
          {fieldProps?.required ? " * " : ""}
        </InputLabel>
      )}
      <Tooltip title={information} placement="right">
        <OutlinedInput
          id={fieldProps?.id}
          name={fieldProps?.name}
          multiline={multiline}
          disabled={disabled}
          className={className}
          minRows={minRows}
          type={
            fieldProps?.type !== "password"
              ? fieldProps?.type
              : showPassword
              ? "text"
              : "password"
          }
          fullWidth
          placeholder={fieldProps?.placeholder}
          // onBlur={form?.handleBlur}
          onChange={(e) => {
            let newValue: string | number = e.target.value;
            form?.setFieldTouched(fieldProps?.name, true);
            if (direction === "rtl") {
              const existingValue =
                value ?? (form?.values as any)?.[fieldProps?.name];
              const diff = newValue?.length - existingValue?.length;
              if (diff === 1) {
                // input
                const newCharacter = newValue?.charAt(newValue.length - 1);
                newValue = newCharacter + existingValue;
              } else if (diff === -1) {
                // Backspace
                newValue = existingValue?.slice(1);
              }
            }
            if (fieldProps?.type === "number") {
              newValue = newValue ? Number(newValue) : newValue;
            }
            if (onChange) {
              onChange(newValue);
            } else {
              form?.setFieldValue(fieldProps?.name, newValue);
            }
          }}
          value={value ?? (form?.values as any)?.[fieldProps?.name]}
          onKeyDown={handleKeyDown}
          endAdornment={
            fieldProps?.type === "password" ? (
              <>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    className="IconButton"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              </>
            ) : (
              <>
                {fieldProps?.postfix && (
                  <InputAdornment position="end" variant="outlined">
                    <Chip
                      tabIndex={-1}
                      label={fieldProps?.postfix}
                      className="adornment-chip-textfield"
                    />
                  </InputAdornment>
                )}
                {children && (
                  <InputAdornment position="end">{children}</InputAdornment>
                )}
              </>
            )
          }
          startAdornment={
            fieldProps?.prefix && (
              <InputAdornment
                position="start"
                className={
                  direction === "ltr" ? "prefixChipRight" : "prefixChipLeft"
                }
              >
                <Chip
                  tabIndex={-1}
                  label={fieldProps?.prefix}
                  className="adornment-chip-textfield"
                />
              </InputAdornment>
            )
          }
          error={
            error
              ? Boolean(error)
              : (form?.touched as any)?.[fieldProps?.name] &&
                Boolean((form?.errors as any)?.[fieldProps?.name])
          }
        />
      </Tooltip>
      {typeof error !== "boolean" && (
        <ErrorMessage
          message={
            error ??
            ((form?.touched as any)?.[fieldProps?.name] &&
              (form?.errors as any)?.[fieldProps?.name])
          }
        />
      )}
    </div>
  );
};

export default InputTextfield;
