import Customer from "../components/customer";
import CustomerList from "../components/customerList";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
const adminRoute = [
  {
    path: "customer",
    element: <CustomerList />,
    name: "Customer",
    index: 3,
    link: "customer",
    icon: PeopleAltIcon,
  },
  {
    path: "customer/create",
    element: <Customer />,
  },
  {
    path: "customer/:id",
    element: <Customer />,
  },
];
export default adminRoute;
