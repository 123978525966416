// import Admin from "../components/admin";
import PeriodsList from "../components/periodsList";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
const periodsRoute = [
  {
    // path: "periods",
    // element: <PeriodsList />,
    // name: "Periods",
    // index: 2,
    // link: "periods",
    // icon: PeopleAltIcon,
  },
  // {
  //   path: "periods/create",
  //   element: <Admin />,
  // },
  // {
  //   path: "periods/:id",
  //   element: <Admin />,
  // },
];
export default periodsRoute;
