import { FC, useContext } from "react";
import "./styles.css";
import { Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { EntityContext } from "../../../contexts/EntityContext";

type SwitchProps = {
  status: boolean;
  handleChange: Function;
  label: string;
};
const Android12Switch = styled(Switch)(({ theme, checked }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderWidth: 1,
    width: 45,
    border: checked
      ? "2px solid var(--zs-enabledSwitch) !important"
      : "2px solid var(--zs-disabledSwitch) !important",
    backgroundColor: checked
      ? "var(--zs-enabledSwitch-bg) !important"
      : "var(--zs-enabledSwitch-bg) !important",
    borderRadius: 0,

    "&::before, &::after": {
      content: '""',
      position: "absolute",
      // top: "50%",
      // transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },

  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 19,
    height: 19,
    marginTop: 0.5,
    marginLeft: 0.5,
    borderRadius: 0,
    color: checked ? "var(--zs-enabledSwitch)" : "#59C2B0 !important",
  },
}));
const ToggleSwitch: FC<SwitchProps> = ({ status, handleChange, label }) => {
  const { entity } = useContext(EntityContext);

  return (
    <div className="switchContainer">
      <Typography
        className="switchLabel"
        id={`${entity.entityTitle}-${status}`}
      >
        {`${entity.entityTitle}-${label}`}
      </Typography>

      <Android12Switch
        checked={status}
        onChange={() => handleChange()}
        inputProps={{ "aria-label": "ant design" }}
      />
    </div>
  );
};

export default ToggleSwitch;
