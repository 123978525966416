/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { matchIsValidTel } from "mui-tel-input";
import useMutation from "../../../../shared/components/hooks/useMutation";
import { getValidationSchema } from "../../../../shared/validations/schema";
import { FormContainer } from "../../../../shared/components/formContainer";
import useLazyQuery from "../../../../shared/components/hooks/useLazyQuery";
import { PhotoCamera } from "@mui/icons-material";

const BlogSettings: FC = () => {
  const { id } = useParams();
  const [image, setImage] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [imagePath, setImagePath] = useState<string>("");
  const [imageName, setImageName] = useState<string>("");
  const { loading: apiLoading, modifyData } = useMutation("/settings/blogs");

  const { loading, data, fetchData } = useLazyQuery(
    `/admin/settings/blog/${id}`,
    "/settings/blogs"
  );
  useEffect(() => {
    if (id) fetchData();
  }, [id]);

  if (data && !preview) {
    setPreview((data as any)?.imageUrl);
  }
  console.log("checkImg", (data as any)?.imageUrl);

  const blogsForm = useFormik({
    initialValues: {
      id: (data as any)?.id ?? "",
      text: (data as any)?.text ?? "",
      order: (data as any)?.order ?? "",
      file: (data as any)?.file ?? "",
      link: (data as any)?.link ?? "",
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema([""]),
    validate: (values: any) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (blogFormData: any) => {
      const formData = new FormData();
      const { file, ...blogInput } = blogFormData;
      formData.append("file", file);
      formData.append("blogInput", JSON.stringify(blogInput));

      modifyData(`/admin/settings/blog/${id}`, "put", formData, blogsForm);
    },
  });

  const titleBar = {
    title: "Update Blog Data",
    buttonTitle: "Update",
    headerTitle: "Blog",
    onClick: () => blogsForm.handleSubmit(),
    dirty: blogsForm.dirty,
    disabled: Object.keys(blogsForm?.errors).length !== 0,
    buttonLoading: apiLoading,
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      blogsForm?.setFieldValue(`file`, file);

      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  console.log("form", preview);

  return (
    <FormContainer titleBar={titleBar} loading={loading}>
      <>
        <h2>Blog Settings</h2>
        <br />
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Blog Heading"
            variant="outlined"
            value={blogsForm?.values?.text}
            onChange={(e: any) => {
              blogsForm?.setFieldValue(`text`, e.target.value);
            }}
            required={true}
          />
        </Grid>
        <br />
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Blog Image"
            variant="outlined"
            value={blogsForm?.values?.file}
            onChange={(e: any) => {
              blogsForm?.setFieldValue(`imageUrl`, e.target.value);
            }}
            required={true}
          />
        </Grid> */}
        <br />

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Blog Link URL"
            variant="outlined"
            value={blogsForm?.values?.link}
            onChange={(e: any) => {
              blogsForm?.setFieldValue(`link`, e.target.value);
            }}
            required={true}
          />
        </Grid>
        <br />
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sort Order</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={blogsForm?.values?.order}
              label="Age"
              onChange={(e: any) => {
                blogsForm?.setFieldValue(`order`, e.target.value);
              }}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <br />
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          {" "}
          <Grid item xs={2}>
            <Typography variant="h5">Upload Image</Typography>
          </Grid>
          <Grid item xs={2}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              // onChange={(e) => {
              //   blogsForm?.setFieldValue(`file`, e.target.files?.[0]);
              // }}
              onChange={handleImageChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<PhotoCamera />}
              >
                Upload
              </Button>
            </label>
          </Grid>
          {preview && (
            <Grid item xs={3}>
              <Avatar
                src={preview}
                alt="Preview"
                variant="rounded"
                sx={{ width: 200, height: 200 }}
              />
            </Grid>
          )}
        </Grid>
        <br />
        <Divider />
      </>
    </FormContainer>
  );
};
export default BlogSettings;
