import {
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { FC, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";

interface DeletePermissionDialogProps {
  deletedData: { name: string; content: string };
  handleClose: Function;
  handleDelete: Function;
  open: boolean;
}
const DeletePermissionDialog: FC<DeletePermissionDialogProps> = ({
  handleClose,
  open,
  deletedData: { content, name },
  handleDelete,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (open === false) {
      setLoading(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography color="black" fontWeight="bold">
          Delete {content?.[0]?.toUpperCase() + content?.substring(1)}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete {name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          id={`delete-${content.toLowerCase()}`}
          variant="contained"
          onClick={() => {
            handleDelete();
            setLoading(true);
          }}
          loading={loading}
          autoFocus
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeletePermissionDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  deletedData: PropTypes.any.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeletePermissionDialog;
