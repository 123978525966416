import {
  Box,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import "./styles.css";
import { FC, useState } from "react";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import PaginationComponent from "../pagination";
import NoDataFound from "../noDataFoundBanner";
import DeletePermissionDialog from "../deletePermissionModal";
import useMutation from "../hooks/useMutation";
import { Link, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import request from "../../../request";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Loader from "../loader/linearProgress";
type DataTableProps = {
  data: Object[];
  entityName?: string;
  linkColum?: string;
  linkUrl?: string;
  pagination: any;
  setPagination: Function;
  displayColumns: string[];
  totalItems?: number;
  deleteApiUrl?: string;
  refetchFunction?: Function;
};

const DataTable: FC<DataTableProps> = ({
  data,
  entityName,
  linkColum,
  linkUrl,
  pagination,
  setPagination,
  displayColumns,
  totalItems,
  deleteApiUrl,
  refetchFunction = () => {},
}) => {
  const { modifyData } = useMutation(linkUrl);
  const { loading: apiLoading, modifyData: modifyMailData } =
    useMutation(linkUrl);

  const [deletedRecord, setDeletedRecord] = useState<any>();
  const navigate = useNavigate();

  const handleDelete = async () => {
    await modifyData(`${deleteApiUrl}/${deletedRecord.id}`, "delete");
    setDeletedRecord(null);
    refetchFunction();
  };

  const booleanStatus: any = {
    verified: {
      true: "Verified",
      false: "Not Verified",
    },
    status: {
      true: "Success",
      false: "Failed",
    },
    enabled: {
      true: "Enabled",
      false: "Disabled",
    },
  };
  // const calculateMaxHeight = () => {
  //   const percentageValue = 50;

  //   const maxHeight = (window.innerHeight * percentageValue) / 100;
  //   return maxHeight;
  // };
  const sendMail = async (dataItem: any) => {
    // const response = await request.put(
    //   `/admin/customers/${dataItem.id}/send-mail`,
    //   { mailType: "account-created" }
    // );
    const url = deleteApiUrl
      ? `${deleteApiUrl}/${dataItem.id}/send-mail`
      : `/admin/customers/${dataItem.id}/send-mail`;

    modifyMailData(url, "put", {
      mailType: "account-created",
    });
  };

  return (
    <>
      {apiLoading ? (
        <Loader />
      ) : (
        <>
          {data?.length > 0 ? (
            <>
              {deletedRecord ? (
                <DeletePermissionDialog
                  open={deletedRecord ? true : false}
                  handleClose={() => {
                    setDeletedRecord(null);
                  }}
                  deletedData={{
                    name: linkColum ? deletedRecord[linkColum] : "",
                    content: entityName ?? "",
                  }}
                  handleDelete={handleDelete}
                />
              ) : (
                ""
              )}
              {/* <Scrollbars autoHeight autoHeightMax="55vh"> */}
              <div className="scrollBar">
                <Table
                  className={
                    linkUrl ? "dataTable editableDataTable" : "dataTable"
                  }
                >
                  <TableHead>
                    <TableRow className="headerRow">
                      <TableCell align="left" className="columnName">
                        Sl No
                      </TableCell>
                      {displayColumns.map((title, columnIndex: number) => {
                        return (
                          <TableCell
                            align={title in booleanStatus ? "center" : "left"}
                            className="columnName"
                            key={columnIndex}
                          >
                            {title === "createdAt"
                              ? "Date & Time"
                              : title.replace(/([a-z])([A-Z])/g, "$1 $2")}
                          </TableCell>
                        );
                      })}
                      {deleteApiUrl ? (
                        <TableCell align="center" className="columnName">
                          Actions
                        </TableCell>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.map((dataItem: any, dataIndex: number) => (
                      <TableRow
                        key={dataIndex}
                        onClick={() => {
                          linkUrl && navigate(`${linkUrl}/${dataItem.id}`);
                        }}
                      >
                        <TableCell align="left" className="tableCell">
                          {pagination?.skip * pagination?.take + dataIndex + 1}
                        </TableCell>
                        {displayColumns.map((title, columnIndex: number) => {
                          return (
                            <TableCell
                              align={
                                typeof dataItem[title] == "boolean"
                                  ? "center"
                                  : "left"
                              }
                              className="tableCell"
                              key={columnIndex}
                            >
                              {linkColum === title ? (
                                <Link
                                  className="link"
                                  to={linkUrl + `/${dataItem.id}`}
                                >
                                  {dataItem[title]}
                                </Link>
                              ) : typeof dataItem[title] == "boolean" ? (
                                <Chip
                                  label={
                                    booleanStatus?.[title]?.[dataItem[title]]
                                  }
                                  variant="outlined"
                                  className={
                                    dataItem[title]
                                      ? "chipEnabled"
                                      : "chipDisabled"
                                  }
                                />
                              ) : title === "createdAt" ? (
                                new Date(dataItem[title]).toLocaleString()
                              ) : (
                                <span>
                                  {dataItem[title]?.length > 35 ? (
                                    <span>
                                      {dataItem[title].slice(0, 35)}
                                      <Tooltip title={dataItem[title]} arrow>
                                        <span className="readMore">
                                          {"....."}
                                          Read more
                                        </span>
                                      </Tooltip>
                                    </span>
                                  ) : (
                                    dataItem[title]
                                  )}
                                </span>
                              )}
                            </TableCell>
                          );
                        })}
                        <TableCell align="center" className="tableCell">
                          {/* <Box display="flex" justifyContent="space-between"> */}
                          <Tooltip title={`Delete ${entityName}`} arrow>
                            <IconButton
                              disabled={
                                dataItem.defaultPropertyId != null &&
                                dataItem.verified === true
                              }
                              id={`delete-${entityName}-${dataItem.id}`}
                              className="deleteIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                setDeletedRecord(dataItem);
                              }}
                            >
                              <DeleteOutlineIcon
                                style={{ color: "#59C2B0" }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Send mail" arrow>
                            <IconButton
                              style={{ color: "#59C2B0" }}
                              id={`delete-${entityName}-${dataItem.id}`}
                              size="small"
                              className="viewIcon"
                              onClick={(e) => {
                                e.stopPropagation();
                                sendMail(dataItem);
                              }}
                            >
                              <SendIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {/* </Box> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {/* </Scrollbars> */}
              </div>
            </>
          ) : (
            <NoDataFound />
          )}
          <Grid className="paginationGrid">
            <PaginationComponent
              totalItems={totalItems}
              pagination={pagination}
              setPagination={setPagination}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default DataTable;
