/* eslint-disable react-hooks/exhaustive-deps */
import "./styles.css";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FC, useContext } from "react";
import TitleBar from "../../../../shared/components/titleBar";
import DataTable from "../../../../shared/components/dataTable";
import { EntityContext } from "../../../../contexts/EntityContext";
import useQuery from "../../../../shared/components/hooks/useQuery";
import { SidebarContext } from "../../../../contexts/SidebarContext";
import Loader from "../../../../shared/components/loader/linearProgress";
import NoPermissionContent from "../../../../shared/components/noPermissionBanner";

const AdminList: FC = () => {
  const navigate = useNavigate();
  const { setEntityName } = useContext(EntityContext);
  const { payload, setPayload } = useContext(SidebarContext);

  const navigateToDetails = () => {
    setEntityName("Administrator");
    navigate("/admin/create");
  };

  const { loading, data, fetchData, error } = useQuery(
    "/admin/administrators",
    {
      params: {
        ...payload,
      },
    }
  );

  const adminDisplayColumns = ["name", "email", "phone", "verified", "enabled"];

  const administrators = (data as any)?.items?.map((administrator: any) => {
    return {
      ...administrator,
      name: `${administrator?.firstName} ${administrator?.lastName}`,
      verified: administrator?.user?.verified,
      phone: administrator?.phone ? `+${administrator?.phone}` : null,
    };
  });

  return (
    <>
      <Container className="adminTableContainer">
        <TitleBar
          title="Administrator"
          buttonTitle="Create"
          headerTitle="Administrator"
          onClick={navigateToDetails}
        />
        {loading ? (
          <Loader />
        ) : (error as any)?.statusCode === 403 ? (
          <NoPermissionContent />
        ) : (
          <DataTable
            entityName="Admin"
            data={administrators}
            displayColumns={adminDisplayColumns}
            linkColum="name"
            linkUrl="/admin"
            pagination={{ take: payload.take, skip: payload.skip }}
            setPagination={setPayload}
            totalItems={(data as any)?.totalItems}
            deleteApiUrl="/admin/administrators"
            refetchFunction={fetchData}
          />
        )}
      </Container>
    </>
  );
};

export default AdminList;
